import { DF_BIRD_FILENAME_V1_0_0, DF_BIRD_URL_V1_0_0 } from "../../consts/Installers";

/**
 * リリースノート英語版（DF BIRD・英語版）
 * @returns JSX.Element
 */
export const ReleaseNotesDfBirdEn = () => {
    return (
        <div>
            {/* v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-09-18</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.0 has been released.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_0}>
                    {DF_BIRD_FILENAME_V1_0_0}
                </a>
            </div>
        </div>
    );
};

export default ReleaseNotesDfBirdEn;
