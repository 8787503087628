import { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";
import { Tooltip } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import { MYPAGE_ROUTE } from "../../consts/routes";
import { CheckmarkCircleIcon, CrossCircleIcon } from "../../components/Icons";
import { ScrollToTop } from "../../components/ScrollToTop";
import {
    createFolder,
    extractFilesByProject,
    extractProjects,
    getFile,
    getFileList,
    putFile,
    removeFile,
} from "../../clients/S3Client";
import { useUserContext } from "../../contexts/UserContext";
import { PROJECT_NAME_MAX_LENGTH } from "../../consts/constants";
import { formatDateTime } from "../../utils/DateUtils";
import S3FileDeleteConfirmModal from "../modals/S3FileDeleteConfirmModal";
import ContextMenu from "./ContextMenu";
import FileUploader from "./FileUploader";
import TiffConvertConfirmModal from "../modals/TiffConvertConfirmModal";
import { invokeTiffConvert } from "../../clients/TiffConvertClient";
import { Helmet } from "react-helmet";

const NEW_PROJECT = "new";

interface HandleUploadProps {
    acceptedFileList: File[];
    setAcceptedFileList: React.Dispatch<React.SetStateAction<File[]>>;
}

/**
 * データ管理
 * @returns JSX.Element
 */
const DataManagement = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, getRecentToken } = useUserContext();
    const [fileList, setFileList] = useState<S3ProviderListOutputItem[]>([]);
    const [filteredFileList, setFilteredFileList] = useState<S3ProviderListOutputItem[]>([]);
    const [projectList, setProjectList] = useState<string[]>([]);
    const [selectedProject, setSelectedProject] = useState<string>("");
    const [newProjectName, setNewProjectName] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<S3ProviderListOutputItem>();
    const [totalSize, setTotalSize] = useState<number>(0);

    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    // S3削除モーダル
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteModalErrorMessage, setDeleteModalErrorMessage] = useState<string>("");

    // TIFF変換確認モーダル
    const [showTiffConvertModal, setShowTiffConvertModal] = useState<boolean>(false);
    const [tiffModalErrorMessage, setTiffModalErrorMessage] = useState<string>("");

    // 操作メニュー関連
    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef<any>(null);

    /**
     * S3からファイルリストの取得
     * @returns
     */
    async function fetchFileList() {
        setLoading(true);
        try {
            // ファイル一覧の取得
            const list: S3ProviderListOutputItem[] = await getFileList(user.attributes.email);
            setFileList(list);
            // プロジェクトの抽出
            const pList = await extractProjects(list);
            setProjectList(pList);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setLoading(false);
        }
    }

    /**
     * S3からファイルをダウンロード
     * @param file
     * @returns
     */
    async function downloadFile(file: S3ProviderListOutputItem) {
        setErrorMessage("");
        try {
            if (file.key) {
                const filename = file.key.substring(file.key.lastIndexOf("/") + 1);
                await getFile(file.key, filename);
            }
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        }
    }

    /**
     * S3からファイルを削除
     * @param file
     * @returns
     */
    async function deleteFile(file: S3ProviderListOutputItem) {
        setIsProcessing(true);
        setDeleteModalErrorMessage("");
        try {
            if (file.key) {
                await removeFile(file.key);
                // ※状態反映待機の１秒のインターバル
                await new Promise((resolve) => setTimeout(resolve, 1000));
                // 表示中のファイルリストから対象ファイルを削除
                setFilteredFileList(filteredFileList.filter((f) => f.key != file.key));
                // メモリ中のファイルリスト更新
                await fetchFileList();
            }
            setShowDeleteModal(false);
        } catch (error) {
            setDeleteModalErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setIsProcessing(false);
        }
    }

    /**
     * S3からフォルダを削除
     * @param file
     * @returns
     */
    async function deleteFolder() {
        setLoading(true);
        try {
            await removeFile(user.attributes.email + "/" + selectedProject + "/");
            // ※状態反映待機の１秒のインターバル
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setSelectedProject("");
            await fetchFileList();
            setSuccessMessage(t("DataManagement.proccessSuccessed"));
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setLoading(false);
        }
    }

    /**
     * TIFF変換処理を呼び出し
     * @param file
     * @returns
     */
    async function convertTiff(file: S3ProviderListOutputItem) {
        setIsProcessing(true);
        setTiffModalErrorMessage("");
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setTiffModalErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API] TIFF変換呼び出しAPI
            if (file.key) {
                await invokeTiffConvert(idToken, file.key);
            }
            setSuccessMessage(t("DataManagement.requestAccepted"));
            window.scrollTo(0, 0); // ページトップへ
            setShowTiffConvertModal(false);
        } catch (error) {
            setTiffModalErrorMessage(t("ErrorMessage.failToSendRequest"));
            return;
        } finally {
            setIsProcessing(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);
    useEffect(() => {
        if (!user) {
            // ページリロード時はコンテキストも初期化されるため、空の場合は一度リターンする
            // コンテキストが生成されユーザー情報が生成された時点で再実行する
            return;
        }
        if (!effectRun.current) {
            fetchFileList();
            return () => {
                effectRun.current = true;
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    /**
     * ストレージ使用量を算出
     */
    useEffect(() => {
        if (fileList) {
            const size: number = fileList.reduce((acc, file) => acc + (file.size ? file.size : 0), 0);
            setTotalSize(size);
        }
    }, [fileList]);

    /**
     * プロジェクト選択時のイベント処理
     */
    const handleProjectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSuccessMessage("");
        setErrorMessage("");
        const pname: string = event.target.value;
        setSelectedProject(pname);
        const newList = extractFilesByProject(fileList, pname);
        setFilteredFileList(newList);
    };

    /**
     * 新規プロジェクト名の入力イベント
     * @param event
     */
    const handleProjectNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const inputValue = event.target.value;
        setNewProjectName(inputValue);
        if (/\s/.test(inputValue)) {
            setErrorMessage(t("DataManagement.cannotContainSpaces"));
        } else {
            setErrorMessage("");
        }
    };

    /**
     * 新規プロジェクト登録押下時のイベント処理
     * @param event
     * @returns
     */
    const handleRegisterProject: MouseEventHandler<HTMLButtonElement> = async (event) => {
        setLoading(true);
        setErrorMessage("");
        try {
            // バリデーションチェック
            if (!newProjectName) {
                setErrorMessage(t("DataManagement.enterProjectName"));
                return;
            } else if (/\s/.test(newProjectName)) {
                setErrorMessage(t("DataManagement.cannotContainSpaces"));
                return;
            }
            // フォルダ作成
            await createFolder(user.attributes.email, newProjectName);
            // ※状態反映待機の１秒のインターバル
            await new Promise((resolve) => setTimeout(resolve, 1000));
            // 選択状態初期化＆ファイル一覧再取得
            setNewProjectName("");
            setSelectedProject("");
            await fetchFileList();
            setSuccessMessage(t("DataManagement.proccessSuccessed"));
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setLoading(false);
        }
    };

    /**
     * ファイルアップロード処理
     * @returns
     */
    const handleUpload = async ({ acceptedFileList, setAcceptedFileList }: HandleUploadProps) => {
        setUploading(true);
        try {
            const uploadPromises = acceptedFileList.map(async (file) => {
                const key: string = user.attributes.email + "/" + selectedProject + "/" + file.name;
                await putFile(key, file);
            });
            await Promise.all(uploadPromises);

            // ※状態反映待機の１秒のインターバル
            await new Promise((resolve) => setTimeout(resolve, 1000));
            // 選択状態初期化＆ファイル一覧再取得
            setAcceptedFileList([]);
            setNewProjectName("");
            setSelectedProject("");
            await fetchFileList();
            setSuccessMessage(t("DataManagement.proccessSuccessed"));
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToConnectStorage"));
            return;
        } finally {
            setUploading(false);
        }
    };

    /**
     * 操作メニューの押下
     * @param e
     */
    const setShowContextMenu = (e: any) => {
        setShowMenu(true);
        setMenuPosition({
            x: e.clientX + window.scrollX,
            y: e.clientY + window.scrollY,
        });
    };

    // クリックイベントがメニュー外で発生したか監視
    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, [showMenu]);

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("DataManagement.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20 lg:px-20">
                <h1 className="h1-common py-3">{t("DataManagement.title")}</h1>

                {/* 成功メッセージ */}
                {successMessage && (
                    <>
                        <div className="flex items-center justify-center py-2">
                            <CheckmarkCircleIcon />
                            <span className="pl-2 text-center text-xl font-bold text-green-500">
                                {successMessage.split("\n").map((line, index) => (
                                    <div key={index}>{line}</div>
                                ))}
                            </span>
                        </div>
                        <p className="pb-2 text-center font-bold text-green-700">
                            {t("DataManagement.reloadAndCheck")}
                        </p>
                    </>
                )}
                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center py-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading && (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="text-gre-800 p-2 font-bold">Loading...</p>
                    </div>
                )}

                {uploading && (
                    // アップロード中
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="text-grey-800 p-2 font-bold">Uploading...</p>
                        <p className="text-grey-800 text-center">
                            <Trans i18nKey={"DataManagement.uploading"} />
                        </p>
                    </div>
                )}

                {!loading && !uploading && (
                    <>
                        {/* プロジェクト選択 */}
                        <div className="mx-auto flex max-w-lg items-center justify-center py-2">
                            <select
                                name="project"
                                id="project"
                                className="textbox-common"
                                value={selectedProject}
                                onChange={handleProjectChange}
                            >
                                <option hidden>{t("DataManagement.selectProject")}</option>
                                {projectList.map((p, index) => (
                                    <option key={index} value={p}>
                                        {p}
                                    </option>
                                ))}
                                <option key={projectList.length} value={NEW_PROJECT}>
                                    {t("DataManagement.newProject")}
                                </option>
                            </select>
                        </div>

                        {/* 新規プロジェクト作成 */}
                        {selectedProject == NEW_PROJECT && (
                            <div className="mx-auto flex max-w-lg items-center justify-center py-2">
                                <input
                                    type="text"
                                    name="new-project"
                                    id="new-project"
                                    placeholder={t("CommonLabel.projectName")}
                                    className="textbox-common"
                                    onChange={handleProjectNameChange}
                                    maxLength={PROJECT_NAME_MAX_LENGTH}
                                    required
                                />
                                <button onClick={handleRegisterProject} className="btn-primary ml-2 w-20">
                                    {t("CommonLabel.register")}
                                </button>
                            </div>
                        )}

                        {/* プロジェクト選択時に表示 */}
                        {selectedProject && selectedProject != NEW_PROJECT && (
                            <div className="mx-auto max-w-3xl overflow-x-auto py-2 transition duration-500 ease-in-out">
                                {/* ファイルアップロード */}
                                <FileUploader totalSize={totalSize} handleUpload={handleUpload} />

                                {/* ファイル一覧処理 */}
                                <div className="mt-8 flex items-center justify-center">
                                    <p className="text-center text-lg font-bold text-gray-600">
                                        {t("DataManagement.listFile")}
                                    </p>
                                    <Tooltip
                                        style={{}}
                                        title={
                                            <ul>
                                                <li>{t("DataManagement.listHelp1")}</li>
                                            </ul>
                                        }
                                        arrow
                                    >
                                        <HelpOutlineIcon
                                            fontSize="small"
                                            className="ml-1 cursor-pointer text-gray-500 hover:text-blue-500"
                                        />
                                    </Tooltip>
                                </div>
                                <table className="min-w-full divide-y divide-gray-200 border">
                                    <thead className="bg-gray-200">
                                        <tr className={`text-sm font-semibold uppercase tracking-wide text-gray-700`}>
                                            {/* [ヘッダ] ファイル名 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.fileName")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 最終更新日時 */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.lastModified")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] サイズ */}
                                            <th scope="col" className="whitespace-nowrap px-6 py-2 text-left">
                                                <div className="flex items-center gap-x-2">
                                                    <span>{t("CommonLabel.dataSize")}</span>
                                                </div>
                                            </th>
                                            {/* [ヘッダ] 操作 */}
                                            <th scope="col" className="whitespace-nowrap px-2 py-2">
                                                <div className="text-center">
                                                    <span>{t("CommonLabel.operation")}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200">
                                        {filteredFileList.map((file: any) => (
                                            <tr key={file.key} className="text-sm font-semibold text-gray-800">
                                                {/* ファイル名 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-2">
                                                        <span>{file.key?.split("/").slice(2).join("/")}</span>
                                                    </div>
                                                </td>
                                                {/* 最終更新日時 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-2">
                                                        <span>
                                                            {file.lastModified ? formatDateTime(file.lastModified) : ""}
                                                        </span>
                                                    </div>
                                                </td>
                                                {/* データサイズ */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-6 py-2 text-right">
                                                        <span>
                                                            {file.size < 1024 * 1024 ? (
                                                                <span>
                                                                    {Number(
                                                                        (file.size / 1024).toFixed(1),
                                                                    ).toLocaleString()}{" "}
                                                                    KB
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {Number(
                                                                        (file.size / (1024 * 1024)).toFixed(1),
                                                                    ).toLocaleString()}{" "}
                                                                    MB
                                                                </span>
                                                            )}
                                                        </span>
                                                    </div>
                                                </td>
                                                {/* ファイル操作 */}
                                                <td className="h-px w-px whitespace-nowrap">
                                                    <div className="px-2 py-2 text-center">
                                                        <ListIcon
                                                            fontSize="small"
                                                            className="cursor-pointer text-gray-600"
                                                            onClick={(e) => {
                                                                setSelectedFile(file);
                                                                setShowContextMenu(e);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {/* プロジェクト削除ボタン（ファイルが空の場合のみ） */}
                                {filteredFileList.length == 0 && (
                                    <div className="mt-10 flex items-center justify-evenly">
                                        <button
                                            type="button"
                                            onClick={() => deleteFolder()}
                                            className="btn-primary w-[150px]"
                                        >
                                            {t("DataManagement.deleteProject")}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* 戻るボタン */}
                <div className="mb-5 mt-10 flex items-center justify-evenly">
                    <button type="button" onClick={() => navigate(MYPAGE_ROUTE)} className="btn-secondary w-[150px]">
                        {t("DataManagement.backToMyPage")}
                    </button>
                </div>
            </div>
            {/* ファイル削除確認モーダル */}
            <S3FileDeleteConfirmModal
                showModal={showDeleteModal}
                setShowModale={setShowDeleteModal}
                selectedFile={selectedFile}
                handleDelete={deleteFile}
                isProcessing={isProcessing}
                errorMessage={deleteModalErrorMessage}
            />
            {/* TIFF変換確認モーダル */}
            <TiffConvertConfirmModal
                showModal={showTiffConvertModal}
                setShowModale={setShowTiffConvertModal}
                selectedFile={selectedFile}
                handleConvert={convertTiff}
                isProcessing={isProcessing}
                errorMessage={tiffModalErrorMessage}
            />
            {/* 操作メニュー */}
            <ContextMenu
                show={showMenu}
                position={menuPosition}
                onClose={() => setShowMenu(false)}
                menuRef={menuRef}
                downloadFile={downloadFile}
                selectedFile={selectedFile}
                setShowDeleteModal={setShowDeleteModal}
                setShowTiffConvertModal={setShowTiffConvertModal}
            />
            <Footer />
        </div>
    );
};

export default DataManagement;
