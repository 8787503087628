import { MouseEventHandler, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import bnr_df_scanner_link_detail from "./../img/bnr_df-scanner_link_detail.png";
import { useUserContext } from "../contexts/UserContext";
import {
    LICENSE_PURCHASE_FORM,
    PRODUCT_CONFIRM_ROUTE,
    PRODUCT_CONFIRM_TRIAL_ROUTE,
    REQUIRED_SIGNIN_ROUTE,
} from "../consts/routes";
import { ScrollToTop } from "../components/ScrollToTop";
import { useTranslation } from "react-i18next";
import LocationConfirmModal from "./modals/LocationConfirmModal";
import i18n from "../i18n/configs";
import DfScannerProPanel from "./prodoctPanels/DfScannerProPanel";
import DfScannerLitePanel from "./prodoctPanels/DfScannerLitePanel";
import DfLatProPanel from "./prodoctPanels/DfLatProPanel";
import DfLatLitePanel from "./prodoctPanels/DfLatLitePanel";
import DfScannerProDfLatProPanel from "./prodoctPanels/DfScannerProDfLatProPanel";
import DfScannerLiteDfLatLitePanel from "./prodoctPanels/DfScannerLiteDfLatLitePanel";
import imageone_logo from "./../img/partners/imageone.png";
import { Helmet } from "react-helmet";
import DfBirdPanel from "./prodoctPanels/DfBirdPanel";

/**
 * 商品選択画面
 * @returns JSX.Element
 */
const ProductSelect = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, setSelectedProductType } = useUserContext();
    const [selectedProductTypeId, setSelectedProductTypeId] = useState<number | undefined>();

    // ライセンス購入時の所在地確認モーダル関連
    const [showLocationConfirmModal, setShowLocationConfirmModal] = useState<boolean>(false);

    /**
     * 商品選択時のイベント処理
     * @param event
     */
    const handleSelectProduct: MouseEventHandler<HTMLButtonElement> = (event) => {
        const buttonValue = event.currentTarget.value;
        const productTypeId = parseInt(buttonValue);
        // 選択したIDをStateに一時保持
        setSelectedProductTypeId(productTypeId);
        setShowLocationConfirmModal(true);
    };

    /**
     * 商品選択時のイベント処理（無料体験版）
     * @param event
     */
    const handleSelectProductAsFreeTrial: MouseEventHandler<HTMLButtonElement> = (event) => {
        // 選択した商品の商品タイプIDをコンテキストに保持
        // -> ログイン後に購入ページへ戻る時に使用する
        const buttonValue = event.currentTarget.value;
        const productTypeId = parseInt(buttonValue);
        setSelectedProductType({ id: productTypeId, isFreeTrial: true });

        if (user) {
            // ログイン済みの場合は購入内容確認画面へ
            navigate(PRODUCT_CONFIRM_TRIAL_ROUTE);
        } else {
            // 未ログインの場合はログイン要求画面へ
            navigate(REQUIRED_SIGNIN_ROUTE);
        }
    };

    /**
     * [ライセンス購入時の所在地確認モーダル]
     * 購入手続きへ進む押下時のイベント処理
     */
    const handleProceedToConfirm = () => {
        if (selectedProductTypeId !== undefined) {
            // 選択した商品の商品タイプIDをコンテキストに保持
            // -> ログイン後に購入ページへ戻る時に使用する
            setSelectedProductType({ id: selectedProductTypeId, isFreeTrial: false });
            if (user) {
                // ログイン済みの場合は購入内容確認画面へ
                navigate(PRODUCT_CONFIRM_ROUTE);
            } else {
                // 未ログインの場合はログイン要求画面へ
                navigate(REQUIRED_SIGNIN_ROUTE);
            }
        }
    };

    /**
     * [ライセンス購入時の所在地確認モーダル]
     * お問い合わせフォームへ進む押下時のイベント処理
     */
    const handleProceedToContact = () => {
        if (i18n.language === "ja") {
            window.open(process.env.REACT_APP_CONTACT_URL);
        } else {
            window.open(process.env.REACT_APP_CONTACT_EN_URL);
        }
    };

    /**
     * [ライセンス購入時の所在地確認モーダル]
     * キャンセル押下時のイベント処理
     */
    const handleCloseModal = () => {
        setSelectedProductTypeId(undefined);
        setShowLocationConfirmModal(false);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("ProductSelect.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                {/* 商品一覧 */}
                <div className="flex items-center py-4">
                    <div className="h-2 flex-grow bg-lime-700 opacity-70"></div>
                    <span className="flex-shrink px-4 text-2xl font-bold text-lime-700">
                        {t("ProductSelect.productList")}
                    </span>
                    <div className="h-2 flex-grow bg-lime-700 opacity-70"></div>
                </div>
                <div className="grid grid-cols-1 items-center justify-center gap-5 md:grid-cols-2 xl:grid-cols-3">
                    {/* DF Scanner Pro */}
                    <DfScannerProPanel
                        handleSelectProduct={handleSelectProduct}
                        handleSelectProductAsFreeTrial={handleSelectProductAsFreeTrial}
                    />

                    {/* DF LAT Pro */}
                    <DfLatProPanel
                        handleSelectProduct={handleSelectProduct}
                        handleSelectProductAsFreeTrial={handleSelectProductAsFreeTrial}
                    />

                    {/* DF BIRD */}
                    <DfBirdPanel
                        handleSelectProduct={handleSelectProduct}
                        handleSelectProductAsFreeTrial={handleSelectProductAsFreeTrial}
                    />

                    {/* DF Scanner Lite */}
                    <DfScannerLitePanel
                        handleSelectProduct={handleSelectProduct}
                        handleSelectProductAsFreeTrial={handleSelectProductAsFreeTrial}
                    />

                    {/* DF LAT Lite */}
                    <DfLatLitePanel
                        handleSelectProduct={handleSelectProduct}
                        handleSelectProductAsFreeTrial={handleSelectProductAsFreeTrial}
                    />

                    {/* DF Scanner Pro × DF LAT Pro */}
                    {/* <DfScannerProDfLatProPanel handleSelectProduct={handleSelectProduct} /> */}

                    {/* DF Scanner Lite × DF LAT Lite */}
                    {/* <DfScannerLiteDfLatLitePanel handleSelectProduct={handleSelectProduct} /> */}
                </div>

                {/* 見積もりフォーム */}
                <div className="mt-8 flex items-center justify-center">
                    <Link to={LICENSE_PURCHASE_FORM}>
                        <button className="rounded-3xl border-2 border-lime-800 bg-lime-700 px-5 py-2.5 text-center text-base font-bold text-lime-50 hover:bg-lime-100 hover:text-lime-800 active:bg-lime-300 sm:text-2xl">
                            {t("ProductSelect.inquiryForm")}
                        </button>
                    </Link>
                </div>

                {/* パートナー企業一覧 */}
                <div className="flex items-center pb-4 pt-16">
                    <div className="h-2 flex-grow bg-lime-700 opacity-70"></div>
                    <span className="flex-shrink px-4 text-2xl font-bold text-lime-700">
                        {t("ProductSelect.partners")}
                    </span>
                    <div className="h-2 flex-grow bg-lime-700 opacity-70"></div>
                </div>
                <div className="grid grid-cols-1 items-center justify-center gap-5 md:grid-cols-2 xl:grid-cols-3">
                    <div className="flex h-full w-full max-w-sm flex-col justify-between rounded-lg border border-gray-300 bg-opacity-50 px-10 pt-5 shadow">
                        <div className="flex items-center justify-center">
                            <img className="" src={imageone_logo} alt="dfscanner & dflat" />
                        </div>
                        <a
                            href={"https://www.geo4d.biz/forest-dx"}
                            className="text-center text-gray-500 hover:text-lime-600 hover:underline"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {"https://www.geo4d.biz/forest-dx"}
                        </a>
                        <div className="my-4"></div>
                    </div>
                </div>

                {/* アプライド社リンク */}
                {/* <div className="pb-2 pt-16 text-lg font-bold text-gray-800 md:text-xl xl:text-2xl">
                    {t("ProductSelect.recommendedPC")}
                </div>
                <div className="flex max-w-sm items-center justify-center md:max-w-3xl xl:max-w-6xl">
                    <a href={process.env.REACT_APP_APPLIED_URL}>
                        <img src={bnr_df_scanner_link_detail} className="h-full w-full" />
                    </a>
                </div> */}
            </div>
            {/* ライセンス購入時の所在地確認モーダル */}
            <LocationConfirmModal
                showModal={showLocationConfirmModal}
                handleClose={handleCloseModal}
                handleProceedToConfirm={handleProceedToConfirm}
                handleProceedToContact={handleProceedToContact}
            />
            <Footer />
        </div>
    );
};

export default ProductSelect;
