import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TECollapse } from "tw-elements-react";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import {
    LICENSE_DETAIL_ROUTE,
    LICENSE_LINKING_ROUTE,
    MYPAGE_ROUTE,
    USER_CREATE_ROUTE,
    USER_UPDATE_ROUTE,
} from "../consts/routes";
import { CheckmarkCircleIcon, CrossCircleIcon } from "../components/Icons";
import { useUserContext } from "../contexts/UserContext";
import { convertUtcToLocalDate, formatUtcToLocalDateTime } from "../utils/DateUtils";
import { deleteUser, getUser, getUsers } from "../clients/UserClient";
import { getLicenses, unlinkLicense } from "../clients/LicenseClient";
import i18n from "../i18n/configs";
import { E102, E103, E201, E202, E205 } from "../consts/ErrorCodes";
import { User } from "../types/User";
import { License } from "../types/License";
import UserDeleteConfirmModal from "./modals/UserDeleteConfirmModal";
import UnlinkingConfirmModal from "./modals/UnlinkingConfirmModal";
import { ScrollToTop } from "../components/ScrollToTop";
import { LicensedUserUnauthorized } from "../components/LicensedUserUnauthorized";
import {
    ACTIVATION_STATUS_OFFLINE,
    USER_TYPE_LICENSED_ADMIN,
    USER_TYPE_MANAGER,
    USER_TYPE_MASTER_USER,
} from "../consts/constants";
import { outputCsv, outputExcel } from "../utils/CsvUtils";
import { LicenseForOutput } from "../types/LicenseForOutput";
import { convertLicenseListForOutput } from "../utils/LicenseUtils";
import PagenationButtons from "../components/PagenationButtons";
import { Helmet } from "react-helmet";

const LICENSES_PER_PAGE = 10;
const USERS_PER_PAGE = 10;

/**
 * ユーザーライセンス管理画面
 * @returns JSX.Element
 */
const UserLicense = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, userType, dbUserCashe, setDbUserCashe, getRecentToken } = useUserContext();
    const [show, setShow] = useState({
        collapseForUsers: true,
        collapseForLicenses: true,
    });
    const [userList, setUserList] = useState<User[]>([]);
    const [licenseList, setLicenseList] = useState<License[]>([]);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [userCurrentPage, setUserCurrentPage] = useState(1);
    const [userTotalPages, setUserTotalPages] = useState(1);
    const [licenceCurrentPage, setLicenseCurrentPage] = useState(1);
    const [licenseTotalPages, setLicenseTotalPages] = useState(1);

    // ユーザー削除モーダル関連
    const [showUserDeleteModal, setShowUserDeleteModal] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
    const [errorMessageForUserDelete, setErrorMessageForUserDelete] = useState<string>("");

    // ライセンス紐づけ解除モーダル関連
    const [showUnlinkingModal, setShowUnlinkingModal] = useState<boolean>(false);
    const [selectedLicense, setSelectedLicense] = useState<License | undefined>(undefined);
    const [isOffline, setIsOffline] = useState<boolean>(false);
    const [errorMessageForUnlinking, setErrorMessageForUnlinking] = useState<string>("");

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * ユーザーおよびライセンス情報一覧の取得処理
     * @returns
     */
    async function fetchUsersLicenses() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]ユーザー情報（自身）の取得
            // TODO キャッシュを使用するとユーザーの権限を変更したときに反映されない場合があるため、毎回DBから取得するようにする
            let groupId;
            // if (dbUserCashe) {
            //     groupId = dbUserCashe.group_id;
            //     dbUserType = dbUserCashe.user_type;
            // } else {
            //     const userResponse = await getUser(idToken, user.attributes.sub);
            //     setDbUserCashe(userResponse);
            //     groupId = userResponse.group_id;
            //     dbUserType = userResponse.user_type;
            // }
            const userResponse = await getUser(idToken, user.attributes.sub);
            setDbUserCashe(userResponse);
            groupId = userResponse.group_id;
            // DBとCognitoの権限情報に不整合がないかチェック
            if (userResponse.user_type != userType) {
                setErrorMessage(t("ErrorMessage.needRelogin"));
                return;
            }

            // [API]ユーザー情報一覧の取得
            const userListResponse: any = await getUsers(idToken, { groupId: groupId });
            setUserList(userListResponse);

            // [API]ライセンス情報一覧の取得
            const licenseListResponse: License[] = await getLicenses(idToken, {
                group_id: groupId,
            });
            const sortedLicenseList: License[] = licenseListResponse.sort((a, b) => {
                return new Date(b.activation_date).getTime() - new Date(a.activation_date).getTime();
            });
            setLicenseList(sortedLicenseList);

            // ページネーション用設定
            setUserTotalPages(Math.ceil(userListResponse.length / USERS_PER_PAGE));
            setLicenseTotalPages(Math.ceil(sortedLicenseList.length / LICENSES_PER_PAGE));
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!user) {
            // ページリロード時はコンテキストも初期化されるため、空の場合は一度リターンする
            // コンテキストが生成されユーザー情報が生成された時点で再実行する
            return;
        }
        if (!effectRun.current) {
            if (
                userType != null &&
                [USER_TYPE_MASTER_USER, USER_TYPE_LICENSED_ADMIN, USER_TYPE_MANAGER].includes(userType)
            ) {
                // マスタユーザー、管理ユーザー、マネージャーの場合のみ情報取得を行う。
                // ※一般ユーザーの場合はページ自体表示不可だが、アクセス時にAPI実行をしてしまうのを防ぐ
                fetchUsersLicenses();
            }
            return () => {
                effectRun.current = true;
            };
        }
    }, [user]);

    /**
     * アコーディオン開閉管理（トグル処理）
     * @param value
     */
    const toggleShow = (value: object) => {
        setShow({ ...show, ...value });
    };

    /**
     * 新しいユーザーを作成押下時のイベント処理
     * @param event
     */
    const handleCreateUser: MouseEventHandler<HTMLButtonElement> = (event) => {
        navigate(USER_CREATE_ROUTE);
    };

    /**
     * ユーザー削除選択時のイベント処理
     * @param user 選択したユーザー情報
     */
    const handleDeleteUser = (user: User) => {
        setSelectedUser(user);
        setSuccessMessage("");
        setErrorMessageForUserDelete("");
        setShowUserDeleteModal(true);
    };

    /**
     * [ユーザー削除の確認モーダル]
     * 削除押下時のイベント処理
     */
    const handleDeleteUserConfirm = async () => {
        setErrorMessageForUserDelete("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessageForUserDelete(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            if (selectedUser) {
                // [API]ユーザー情報削除処理（Cognito更新True）
                await deleteUser(idToken, selectedUser.user_id, true);
            } else {
                setErrorMessageForUserDelete(t("ErrorMessage.userNotFound"));
                return;
            }
            // 成功時はモーダルを閉じる
            setSelectedUser(undefined);
            setSuccessMessage(t("CommonMessage.deleteCompleted"));
            setShowUserDeleteModal(false);
            fetchUsersLicenses();
            window.scrollTo(0, 0);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.code) {
                const errorCode = error.response.data.code;
                if (errorCode === E102) {
                    // ユーザーが見つからない
                    setErrorMessageForUserDelete(t("ErrorMessage.userNotFound"));
                } else if (errorCode === E103) {
                    // 管理ユーザーがいない
                    setErrorMessageForUserDelete(t("ErrorMessage.adminMustExistInGroup"));
                } else {
                    // その他エラー
                    setErrorMessageForUserDelete(t("ErrorMessage.failToDeleteUser"));
                }
            } else {
                // その他エラー
                setErrorMessageForUserDelete(t("ErrorMessage.failToDeleteUser"));
            }
            return;
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * [ユーザー削除の確認モーダル]
     * キャンセル押下時のイベント処理
     */
    const handleCloseUserDeleteModal = () => {
        setSelectedUser(undefined);
        setShowUserDeleteModal(false);
    };

    /**
     * ライセンス紐づけ解除選択時のイベント処理
     * @param license 選択したライセンス情報
     */
    const handleUnlinking = (license: License) => {
        setSelectedLicense(license);
        setSuccessMessage("");
        if (license.activation_status === ACTIVATION_STATUS_OFFLINE) {
            setErrorMessageForUnlinking(t("ErrorMessage.cannotUnlinkingOffline"));
            setIsOffline(true);
        } else {
            setErrorMessageForUnlinking("");
            setIsOffline(false);
        }
        setShowUnlinkingModal(true);
    };

    /**
     * ユーザーリストのCSV/Excel出力押下時のイベント処理
     */
    const handleUserListOutput: MouseEventHandler<HTMLButtonElement> = async (event) => {
        const format = event.currentTarget.value;
        const headers = [
            { header: "メールアドレス", key: "email" },
            { header: "姓", key: "family_name" },
            { header: "名", key: "given_name" },
            { header: "所属", key: "organization" },
            { header: "user_id", key: "user_id" },
            { header: "user_type", key: "user_type" },
        ];
        if (format === "csv") {
            outputCsv(headers, userList, "users");
        } else {
            outputExcel(headers, userList, "users");
        }
    };

    /**
     * ライセンスリストのCSV/Excel出力押下時のイベント処理
     */
    const handleLicenseListOutput: MouseEventHandler<HTMLButtonElement> = async (event) => {
        const format = event.currentTarget.value;
        const headers = [
            { header: "ID", key: "license_id" },
            { header: "製品名", key: "package_name" },
            { header: "ライセンスキー", key: "license_key" },
            { header: "無料体験フラグ", key: "free_trial_flg" },
            { header: "利用開始日", key: "activation_date" },
            { header: "有効期限", key: "expire_date" },
            { header: "アクティベーションステータス", key: "activation_status_string" },
            { header: "アクティベーション日時", key: "activation_record_date" },
            { header: "コンピュータ名", key: "computer_name" },
            { header: "ユーザーメールアドレス", key: "email" },
            { header: "ユーザー姓", key: "family_name" },
            { header: "ユーザー名", key: "given_name" },
            { header: "ユーザー所属", key: "organization" },
        ];
        const outputList: LicenseForOutput[] = convertLicenseListForOutput(licenseList, userList);
        if (format === "csv") {
            outputCsv(headers, outputList, "licenses");
        } else {
            outputExcel(headers, outputList, "licences");
        }
    };

    /**
     * [ライセンス紐づけ解除確認モーダル]
     * 実行押下時のイベント処理
     */
    const handleUnlinkingConfirm = async () => {
        setErrorMessageForUnlinking("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessageForUnlinking(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            if (selectedLicense) {
                // [API]ライセンス紐づけ解除処理
                await unlinkLicense(idToken, selectedLicense.license_key, selectedLicense.user_id);
            } else {
                setErrorMessageForUnlinking(t("ErrorMessage.licenseNotFound"));
                return;
            }
            // 成功時はモーダルを閉じる
            setSelectedLicense(undefined);
            setSuccessMessage(t("CommonMessage.unlinkCompleted"));
            setShowUnlinkingModal(false);
            fetchUsersLicenses();
            window.scrollTo(0, 0);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.code) {
                const errorCode = error.response.data.code;
                if (errorCode === E201) {
                    // ライセンス情報が見つからない
                    setErrorMessageForUnlinking(t("ErrorMessage.licenseNotFound"));
                } else if (errorCode === E202) {
                    // ユーザーが見つからない
                    setErrorMessageForUnlinking(t("ErrorMessage.userNotFound"));
                } else if (errorCode === E205) {
                    // 他のユーザーがライセンス使用中
                    setErrorMessageForUnlinking(t("ErrorMessage.licenseInUse"));
                } else {
                    // その他エラー
                    setErrorMessageForUnlinking(t("ErrorMessage.failToLicenseUnlinking"));
                }
            } else {
                // その他エラー
                setErrorMessageForUnlinking(t("ErrorMessage.failToLicenseUnlinking"));
            }
            return;
        } finally {
            setIsProcessing(false);
        }
    };

    /**
     * [ライセンス紐づけ解除確認モーダル]
     * キャンセル押下時のイベント処理
     */
    const handleCloseUnlinkingModal = () => {
        setSelectedLicense(undefined);
        setShowUnlinkingModal(false);
    };

    /**
     * 購入管理番号が同じライセンス（同時に購入したライセンス）の中に、
     * オフラインアクティベーション中のライセンスが存在するかを調べる。
     *
     * ライセンス詳細画面で、ライセンス更新操作が可能かどうかを調べるために使用する。
     * @param license ターゲットとなるライセンス情報
     * @returns 成否
     */
    function hasOfflineActivationInPaymentGroup(license: License): boolean {
        return licenseList
            .filter((l) => l.payment_history_id === license.payment_history_id)
            .map((l) => l.activation_status)
            .includes(ACTIVATION_STATUS_OFFLINE);
    }

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("UserLicense.meta.title")} />
            <ScrollToTop />
            <LicensedUserUnauthorized />
            <DFNavbar bottomPadding={true} />
            <div className="flex-grow items-center justify-center px-6 py-8 pt-20 lg:px-20">
                <h1 className="h1-common py-3">{t("UserLicense.userLicenseTitle")}</h1>

                {/* 成功メッセージ */}
                {successMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CheckmarkCircleIcon />
                        <span className="pl-2 text-center text-xl font-bold text-green-500">
                            {successMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}
                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center pt-4">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {loading ? (
                    // ローディング
                    <div className="flex flex-col items-center justify-center p-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="p-2 font-bold text-lime-800">Loading...</p>
                    </div>
                ) : (
                    <>
                        {/* ユーザー管理部分 */}
                        <div id="accordionForUsers">
                            <div className="rounded border-none border-neutral-200 bg-white">
                                {/* マスターユーザー、マネージャーの場合は出力ボタンを表示 */}
                                {userType != null && [USER_TYPE_MASTER_USER, USER_TYPE_MANAGER].includes(userType) ? (
                                    <div className="mt-2 flex items-center justify-end gap-5">
                                        {/* CSV出力ボタン */}
                                        <div className="mb-1 flex items-center justify-evenly">
                                            <button
                                                type="button"
                                                onClick={handleUserListOutput}
                                                value="csv"
                                                className={`${
                                                    userList.length === 0
                                                        ? "bg-gray-300 text-gray-600"
                                                        : "bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 active:bg-gray-500"
                                                } w-[150px] rounded-lg py-1 text-center font-medium`}
                                                disabled={userList.length === 0}
                                            >
                                                {t("CommonLabel.csvOutput")}
                                            </button>
                                        </div>
                                        {/* Excel出力ボタン */}
                                        <div className="mb-1 flex items-center justify-evenly">
                                            <button
                                                type="button"
                                                onClick={handleUserListOutput}
                                                value="excel"
                                                className={`${
                                                    userList.length === 0
                                                        ? "bg-gray-300 text-gray-600"
                                                        : "bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 active:bg-gray-500"
                                                } w-[150px] rounded-lg py-1 text-center font-medium`}
                                                disabled={userList.length === 0}
                                            >
                                                {t("CommonLabel.excelOutput")}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mt-5"></div>
                                )}

                                {/* ユーザーを管理するラベル */}
                                <h2 className="mb-0">
                                    <button
                                        className={`group relative flex w-full items-center rounded border-0 bg-lime-700 px-5 py-2 text-left text-lg font-bold text-neutral-200 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none`}
                                        type="button"
                                        onClick={() =>
                                            toggleShow({ ...show, collapseForUsers: !show.collapseForUsers })
                                        }
                                        aria-expanded="true"
                                        aria-controls="collapseForUsers"
                                    >
                                        {t("UserLicense.userManagement")}
                                        <span
                                            className={`${
                                                show.collapseForUsers ? `rotate-[-180deg]` : `rotate-0 fill-[#212529]`
                                            } ml-auto h-5 w-5 shrink-0 transition-transform duration-500 ease-in-out motion-reduce:transition-none`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="3.0"
                                                stroke="currentColor"
                                                className="h-6 w-6"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                </h2>

                                {/* アコーディオン中身 */}
                                <TECollapse show={show.collapseForUsers} className="!mt-0 !rounded-b-none !shadow-none">
                                    <div
                                        className={`${
                                            show.collapseForUsers ? "opacity-100" : "max-h-0 opacity-0"
                                        } overflow-x-auto transition duration-500 ease-in-out`}
                                    >
                                        <table className="my-3 min-w-full divide-y divide-gray-200 border">
                                            <thead className="bg-lime-100">
                                                <tr
                                                    className={`${
                                                        i18n.language === "ja" ? "text-base" : "text-sm"
                                                    } font-semibold uppercase tracking-wide text-lime-800`}
                                                >
                                                    {/* [ヘッダ] メールアドレス */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.email")}</span>
                                                        </div>
                                                    </th>
                                                    {/* [ヘッダ] 姓 */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.familyName")}</span>
                                                        </div>
                                                    </th>
                                                    {/* [ヘッダ] 名 */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.givenName")}</span>
                                                        </div>
                                                    </th>
                                                    {/* [ヘッダ] 所属 */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.organization")}</span>
                                                        </div>
                                                    </th>
                                                    {/* [ヘッダ] ユーザータイプ */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.userType")}</span>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-3 py-3 text-left"></th>
                                                    <th scope="col" className="px-3 py-3 text-left"></th>
                                                </tr>
                                            </thead>

                                            <tbody className="divide-y divide-gray-200">
                                                {userList
                                                    .slice(
                                                        (userCurrentPage - 1) * USERS_PER_PAGE,
                                                        (userCurrentPage - 1) * USERS_PER_PAGE + USERS_PER_PAGE,
                                                    )
                                                    .map((u) => (
                                                        <tr
                                                            key={u.user_id}
                                                            className="text-sm font-semibold text-gray-800"
                                                        >
                                                            {/* メールアドレス */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>{u.email}</span>
                                                                </div>
                                                            </td>
                                                            {/* 姓 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>{u.family_name}</span>
                                                                </div>
                                                            </td>
                                                            {/* 名 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>{u.given_name}</span>
                                                                </div>
                                                            </td>
                                                            {/* 所属 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>{u.organization}</span>
                                                                </div>
                                                            </td>
                                                            {/* ユーザータイプ */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>{t(`UserType.${u.user_type}`)}</span>
                                                                </div>
                                                            </td>
                                                            {/* 編集 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                {user?.attributes.sub !== u.user_id && (
                                                                    // 自身以外のユーザーのみ編集リンクを表示
                                                                    <div className="px-3 py-1.5">
                                                                        <Link
                                                                            className="inline-flex items-center gap-x-1.5 text-sm font-medium text-blue-600 decoration-2 hover:underline"
                                                                            to={USER_UPDATE_ROUTE}
                                                                            state={{ selectedUser: u }}
                                                                        >
                                                                            {t("CommonLabel.edit")}
                                                                        </Link>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            {/* 削除 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                {user?.attributes.sub !== u.user_id && (
                                                                    // 自身以外のユーザーのみ編集リンクを表示
                                                                    <div className="px-3 py-1.5">
                                                                        <button
                                                                            className="inline-flex items-center gap-x-1.5 text-sm font-medium text-blue-600 decoration-2 hover:underline"
                                                                            onClick={() => handleDeleteUser(u)}
                                                                        >
                                                                            {t("CommonLabel.delete")}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        {/* ページネーションコントロール */}
                                        <PagenationButtons
                                            currentPage={userCurrentPage}
                                            setCurrentPage={setUserCurrentPage}
                                            totalPages={userTotalPages}
                                        />

                                        {/* 新しいユーザーを作成 */}
                                        <div className="flex items-center justify-center pt-4">
                                            <button
                                                onClick={handleCreateUser}
                                                className="btn-green-white mb-3 px-5 py-2"
                                            >
                                                {t("UserLicense.createNewUser")}
                                            </button>
                                        </div>
                                    </div>
                                </TECollapse>
                            </div>
                        </div>

                        {/* ライセンス管理部分 */}
                        <div id="accordionForLicenses">
                            <div className="rounded border-none border-neutral-200 bg-white">
                                {/* マスターユーザー、マネージャーの場合は出力ボタンを表示 */}
                                {userType != null && [USER_TYPE_MASTER_USER, USER_TYPE_MANAGER].includes(userType) ? (
                                    <div className="mt-5 flex items-center justify-end gap-5">
                                        {/* CSV出力ボタン */}
                                        <div className="mb-1 flex items-center justify-evenly">
                                            <button
                                                type="button"
                                                onClick={handleLicenseListOutput}
                                                value="csv"
                                                className={`${
                                                    licenseList.length === 0
                                                        ? "bg-gray-300 text-gray-600"
                                                        : "bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 active:bg-gray-500"
                                                } w-[150px] rounded-lg py-1 text-center font-medium`}
                                                disabled={licenseList.length === 0}
                                            >
                                                {t("CommonLabel.csvOutput")}
                                            </button>
                                        </div>
                                        {/* Excel出力ボタン */}
                                        <div className="mb-1 flex items-center justify-evenly">
                                            <button
                                                type="button"
                                                onClick={handleLicenseListOutput}
                                                value="excel"
                                                className={`${
                                                    licenseList.length === 0
                                                        ? "bg-gray-300 text-gray-600"
                                                        : "bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 active:bg-gray-500"
                                                } w-[150px] rounded-lg py-1 text-center font-medium`}
                                                disabled={licenseList.length === 0}
                                            >
                                                {t("CommonLabel.excelOutput")}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mt-10"></div>
                                )}

                                {/* ライセンスを管理するラベル */}
                                <h2 className="mb-0">
                                    <button
                                        className={`group relative flex w-full items-center rounded border-0 bg-lime-700 px-5 py-2 text-left text-lg font-bold text-neutral-200 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none`}
                                        type="button"
                                        onClick={() =>
                                            toggleShow({ ...show, collapseForLicenses: !show.collapseForLicenses })
                                        }
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        {t("UserLicense.licenseManagement")}
                                        <span
                                            className={`${
                                                show.collapseForLicenses ? `rotate-[-180deg]` : `rotate-0`
                                            } ml-auto h-5 w-5 shrink-0 transition-transform duration-500 ease-in-out motion-reduce:transition-none`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="3.0"
                                                stroke="currentColor"
                                                className="h-6 w-6"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                </h2>

                                {/* アコーディオン中身 */}
                                <TECollapse
                                    show={show.collapseForLicenses}
                                    className="!mt-0 !rounded-b-none !shadow-none"
                                >
                                    <div
                                        className={`${
                                            show.collapseForLicenses ? "opacity-100" : "max-h-0 opacity-0"
                                        } overflow-x-auto transition duration-500 ease-in-out`}
                                    >
                                        <table className="my-3 min-w-full divide-y divide-gray-200 border">
                                            <thead className="bg-lime-100">
                                                <tr
                                                    className={`${
                                                        i18n.language === "ja" ? "text-base" : "text-sm"
                                                    } font-semibold uppercase tracking-wide text-lime-800`}
                                                >
                                                    {/* [ヘッダ] 製品名 */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.packageName")}</span>
                                                        </div>
                                                    </th>
                                                    {/* [ヘッダ] ライセンスコード */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.licenseKey")}</span>
                                                        </div>
                                                    </th>
                                                    {/* [ヘッダ] 利用開始日 */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.activationDate")}</span>
                                                        </div>
                                                    </th>
                                                    {/* [ヘッダ] 有効期限 */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.expirationDate")}</span>
                                                        </div>
                                                    </th>
                                                    {/* [ヘッダ] ユーザー */}
                                                    <th scope="col" className="px-6 py-2 text-left">
                                                        <div className="flex items-center gap-x-2">
                                                            <span>{t("CommonLabel.user")}</span>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-6 py-2 text-right"></th>
                                                    <th scope="col" className="px-6 py-2 text-right"></th>
                                                </tr>
                                            </thead>

                                            <tbody className="divide-y divide-gray-200">
                                                {licenseList
                                                    .slice(
                                                        (licenceCurrentPage - 1) * LICENSES_PER_PAGE,
                                                        (licenceCurrentPage - 1) * LICENSES_PER_PAGE +
                                                            LICENSES_PER_PAGE,
                                                    )
                                                    .map((license) => (
                                                        <tr
                                                            key={license.license_id}
                                                            className="text-sm font-semibold text-gray-800"
                                                        >
                                                            {/* 製品名 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>{license.package_name}</span>
                                                                </div>
                                                            </td>
                                                            {/* ライセンスコード */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>{license.license_key}</span>
                                                                </div>
                                                            </td>
                                                            {/* 利用開始日 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>
                                                                        {formatUtcToLocalDateTime(
                                                                            license.activation_date,
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            {/* 有効期限 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span
                                                                        className={
                                                                            new Date() >
                                                                            convertUtcToLocalDate(license.expire_date)
                                                                                ? "text-red-700"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {formatUtcToLocalDateTime(license.expire_date)}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            {/* ユーザー */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-6 py-3">
                                                                    <span>{license.email || "-"}</span>
                                                                </div>
                                                            </td>
                                                            {/* 紐づけを解除/ユーザーを紐づけ */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-2 py-1.5">
                                                                    {license.email ? (
                                                                        <button
                                                                            className="inline-flex items-center gap-x-1.5 text-sm font-medium text-blue-600 decoration-2 hover:underline"
                                                                            onClick={() => handleUnlinking(license)}
                                                                        >
                                                                            {t("UserLicense.unlinking")}
                                                                        </button>
                                                                    ) : (
                                                                        <Link
                                                                            className="inline-flex items-center gap-x-1.5 text-sm font-medium text-blue-600 decoration-2 hover:underline"
                                                                            to={LICENSE_LINKING_ROUTE}
                                                                            state={{
                                                                                license: license,
                                                                                userList: userList,
                                                                            }}
                                                                        >
                                                                            {t("UserLicense.linking")}
                                                                        </Link>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            {/* 詳細 */}
                                                            <td className="h-px w-px whitespace-nowrap">
                                                                <div className="px-2 py-1.5">
                                                                    <Link
                                                                        className="inline-flex items-center gap-x-1.5 text-sm font-medium text-blue-600 decoration-2 hover:underline"
                                                                        to={LICENSE_DETAIL_ROUTE}
                                                                        state={{
                                                                            license: license,
                                                                            hasOfflineActivationInPaymentGroup:
                                                                                hasOfflineActivationInPaymentGroup(
                                                                                    license,
                                                                                ),
                                                                        }}
                                                                    >
                                                                        {t("CommonLabel.detail")}
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        {/* ページネーションコントロール */}
                                        <PagenationButtons
                                            currentPage={licenceCurrentPage}
                                            setCurrentPage={setLicenseCurrentPage}
                                            totalPages={licenseTotalPages}
                                        />
                                    </div>
                                </TECollapse>
                            </div>
                        </div>
                    </>
                )}

                {/* マイページへ戻る */}
                <div className="flex justify-center pt-6">
                    <Link to={MYPAGE_ROUTE}>
                        <button className="btn-secondary px-5">{t("UserLicense.backToMypage")}</button>
                    </Link>
                </div>
            </div>
            {/* ユーザー削除確認モーダル */}
            <UserDeleteConfirmModal
                showModal={showUserDeleteModal}
                selectedUser={selectedUser}
                handleClose={handleCloseUserDeleteModal}
                handleDelete={handleDeleteUserConfirm}
                isProcessing={isProcessing}
                errorMessage={errorMessageForUserDelete}
            />
            {/* ライセンス紐づけ解除確認モーダル */}
            <UnlinkingConfirmModal
                showModal={showUnlinkingModal}
                selectedLicense={selectedLicense}
                isOffline={isOffline}
                handleClose={handleCloseUnlinkingModal}
                handleUnlinking={handleUnlinkingConfirm}
                isProcessing={isProcessing}
                errorMessage={errorMessageForUnlinking}
            />
            <Footer />
        </div>
    );
};

export default UserLicense;
