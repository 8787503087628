/** バックエンドAPI識別名 */
export const API_ENDPOINT_NAME = process.env.REACT_APP_API_ENDPOINT_NAME ?? "DFGeoServerAPIs";

/** ユーザータイプ */
export const UserTypes: { [key: number]: string } = {
    0: "マスターユーザー",
    1: "管理ユーザー",
    2: "一般ユーザー",
    3: "マネージャー",
};
/** マスターユーザー */
export const USER_TYPE_MASTER_USER = 0;
/** 管理ユーザー */
export const USER_TYPE_LICENSED_ADMIN = 1;
/** 一般ユーザー */
export const USER_TYPE_LICENSED_USER = 2;
/** マネージャー */
export const USER_TYPE_MANAGER = 3;

/** DeepForest（直販・個別対応分）の代理店ID */
export const AGENCY_ID_DF_OTHER = -1;
/** DeepForest（直販）の代理店ID */
export const AGENCY_ID_DF = 0;

/** 商品タイプ */
export const ProductType: { [key: number]: string } = {
    1: "DF Scanner",
    2: "DF LAT",
    3: "DF Scanner & DF LAT",
    11: "DF Scanner Pro",
    12: "DF Scanner Lite",
    21: "DF LAT Pro",
    22: "DF LAT Lite",
    31: "DF Scanner Pro × DF LAT Pro",
    32: "DF Scanner Lite × DF LAT Lite",
    33: "DF Scanner Pro × DF LAT Lite",
    34: "DF Scanner Lite × DF LAT Pro",
    41: "DF BIRD",
};
/** 商品タイプID：DF Scanner */
export const PRODUCT_TYPE_ID_DF_SCANNER = 1;
/** 商品タイプID：DF LAT */
export const PRODUCT_TYPE_ID_DF_LAT = 2;
/** 商品タイプID：DF Scanner & DF LAT */
export const PRODUCT_TYPE_ID_DF_SCANNER_DF_LAT = 3;
/** 商品タイプID：DF Scanner Pro */
export const PRODUCT_TYPE_ID_DF_SCANNER_PRO = 11;
/** 商品タイプID：DF Scanner Lite */
export const PRODUCT_TYPE_ID_DF_SCANNER_LITE = 12;
/** 商品タイプID：DF LAT Pro */
export const PRODUCT_TYPE_ID_DF_LAT_PRO = 21;
/** 商品タイプID：DF LAT Lite */
export const PRODUCT_TYPE_ID_DF_LAT_LITE = 22;
/** 商品タイプID：DF Scanner Pro × DF LAT Pro */
export const PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_PRO = 31;
/** 商品タイプID：DF Scanner Lite × DF LAT Lite */
export const PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_LITE = 32;
/** 商品タイプID：DF Scanner Pro × DF LAT Lite */
export const PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_LITE = 33;
/** 商品タイプID：DF Scanner Lite × DF LAT Pro */
export const PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_PRO = 34;
/** 商品タイプID：DF BIRD */
export const PRODUCT_TYPE_ID_DF_BIRD = 41;

/** アクティベーションステータス */
export const ActivationStatus: { [key: number]: string } = {
    0: "未アクティベーション",
    1: "アクティベーション済み（オンライン）",
    2: "アクティベーション済み（オフライン）",
    3: "利用開始前",
    4: "有効期限切れ",
};
/** アクティベーション：未アクティベーション */
export const ACTIVATION_STATUS_AVAILABLE = 0;
/** アクティベーション：オンライン */
export const ACTIVATION_STATUS_ONLINE = 1;
/** アクティベーション：オフライン */
export const ACTIVATION_STATUS_OFFLINE = 2;
/** アクティベーション：利用開始前 */
export const ACTIVATION_STATUS_BEFORE = 3;
/** アクティベーション：有効期限切れ */
export const ACTIVATION_STATUS_EXPIRED = 4;

/** メールアドレス最大文字数 */
export const EMAIL_MAX_LENGTH = 255;
/** パスワード最大文字数 */
export const PASSWORD_MAX_LENGTH = 256;
/** 姓最大文字数 */
export const FAMILY_NAME_MAX_LENGTH = 64;
/** 名最大文字数 */
export const GIVEN_NAME_MAX_LENGTH = 64;
/** 所属最大文字数 */
export const ORGANIZATION_MAX_LENGTH = 64;
/** 認証コード最大文字数 */
export const VERIFICATION_CODE_MAX_LENGTH = 6;
/** プロジェクト名最大文字数 */
export const PROJECT_NAME_MAX_LENGTH = 64;

/** 日本円 */
export const JPY = "JPY";
/** USドル */
export const USD = "USD";
/** ユーロ */
export const EUR = "EUR";
