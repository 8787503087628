import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import toppage_bg_img from "./../img/toppage_bg_img.jpg";
import top_deepforest_logo from "./../img/top_deepforest_logo.svg";
import dfscanner_top_img from "./../img/dfscanner/topimage.png";
import dflat_top_img from "./../img/dflat/topimage.png";
import dfviewer_top_img from "./../img/dfviewer/topimage.png";
import dfwalker_top_img from "./../img/dfwalker/topimage.png";
import dfbird_top_img from "./../img/dfbird/topimage.png";
import { useUserContext } from "../contexts/UserContext";
import {
    DETAIL_DF_BIRD_ROUTE,
    DETAIL_DF_LAT_ROUTE,
    DETAIL_DF_SCANNER_ROUTE,
    DETAIL_DF_VIEWER_ROUTE,
    DETAIL_DF_WALKER_ROUTE,
    MYPAGE_ROUTE,
    SIGNIN_ROUTE,
} from "../consts/routes";
import { ScrollToTop } from "../components/ScrollToTop";
import AnimationTrigger from "../components/AnimationTrigger";
import i18n from "../i18n/configs";
import { Helmet } from "react-helmet";

/**
 * トップ画面
 * @returns JSX.Element
 */
const Top = () => {
    const { t } = useTranslation();
    const { user } = useUserContext();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("Top.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={false} />
            <div className="tp-0 top-0 flex flex-grow flex-col">
                <div
                    className="bg-16 local h-80 w-full bg-cover bg-center"
                    style={{ backgroundImage: `url(${toppage_bg_img})` }}
                >
                    <div className="flex h-full w-full flex-col items-center justify-end pb-10 backdrop-brightness-75">
                        <div className="mb-5 rounded-md bg-black bg-opacity-50 px-10 sm:px-20">
                            <img className="y-[125px] w-[250px]" src={top_deepforest_logo} alt="Logo" />
                        </div>
                        <Link to={user ? MYPAGE_ROUTE : SIGNIN_ROUTE}>
                            <button className="btn-white text-x relative z-10 mx-auto flex h-12 w-52 items-center justify-center bg-opacity-80 py-4 text-lg text-lime-950">
                                {t("Top.dfaccountMenu")}
                            </button>
                        </Link>
                    </div>
                </div>

                {/* YouTube埋め込み */}
                {/* <div className="mt-3">
                    <section className="bg-gradient-to-b from-white via-gray-200 to-white p-3 sm:px-10">
                        <div className="mx-auto flex max-w-screen-md flex-col">
                            <div className="">
                                <h2 className="mb-2 flex items-center justify-center bg-lime-50 text-lg font-bold text-lime-900 sm:text-3xl">
                                    {t("Top.youtubeTitle")}
                                </h2>
                                <div className="mb-2 flex items-center justify-center">
                                    <p className="text-gray-900 sm:text-lg">
                                        <Trans
                                            i18nKey="Top.youtubeLink"
                                            components={{
                                                l: (
                                                    <a
                                                        className="link-text"
                                                        href={"https://www.youtube.com/@deepforesttechnologies3698"}
                                                    />
                                                ),
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mx-auto flex max-w-md flex-col">
                            <div className="flex items-center justify-center"></div>
                            <LiteYouTubeEmbed
                                id={i18n.language === "ja" ? "Gm5vcmDi7Nw" : "iWnZyWAm3Kw"}
                                title="YouTube"
                                noCookie={true}
                            />
                        </div>
                    </section>
                </div> */}

                {/* DF Scanner */}
                <AnimationTrigger animation="fadeIn" rootMargin="100px" triggerOnce>
                    <div className="mb-3">
                        <section className="bg-gradient-to-b from-white via-lime-100 to-white p-5 sm:px-10">
                            <div className="mx-auto flex max-w-screen-xl flex-col justify-between gap-3 sm:gap-8 md:flex-row">
                                <div className="flex flex-col justify-center sm:text-center md:w-7/12 md:text-left xl:w-8/12">
                                    <p className="mb-4 font-semibold text-lime-700 md:text-lg xl:text-xl">
                                        {t("Top.dfscannerHeader")}
                                    </p>
                                    <h1 className="mb-4 text-3xl font-bold text-lime-950 sm:text-4xl md:mb-6">
                                        DF Scanner
                                    </h1>

                                    <p className="mb-4 leading-relaxed text-gray-700 md:mb-6 xl:text-lg">
                                        <Trans i18nKey="Top.dfscannerText" />
                                    </p>

                                    <div className="flex flex-col gap-2.5 sm:flex-row sm:justify-center md:justify-start">
                                        <Link
                                            to={DETAIL_DF_SCANNER_ROUTE}
                                            className="inline-block rounded-lg border border-lime-800 bg-white px-6 py-3 text-center text-sm font-semibold text-gray-700 transition duration-100 hover:bg-lime-100 focus-visible:ring active:bg-lime-200 md:text-base"
                                        >
                                            {t("Top.moreInfo")}
                                        </Link>
                                    </div>
                                </div>

                                <div className="h-auto overflow-hidden rounded-lg bg-gray-100 shadow-lg md:my-5 md:w-5/12 xl:w-4/12">
                                    <img src={dfscanner_top_img} className="h-full w-full object-cover object-center" />
                                </div>
                            </div>
                        </section>
                    </div>
                </AnimationTrigger>

                {/* DF LAT */}
                <AnimationTrigger animation="fadeIn" rootMargin="100px" triggerOnce>
                    <div className="my-3">
                        <section className="bg-gradient-to-b from-white via-sky-100 to-white p-5 sm:px-10">
                            <div className="mx-auto flex max-w-screen-xl flex-col justify-between gap-3 sm:gap-8 md:flex-row">
                                <div className="flex flex-col justify-center sm:text-center md:w-7/12 md:text-left xl:w-8/12">
                                    <p className="mb-4 font-semibold text-sky-800 md:text-lg xl:text-xl">
                                        {t("Top.dflatHeader")}
                                    </p>
                                    <h1 className="mb-4 text-3xl font-bold text-sky-950 sm:text-4xl md:mb-6">DF LAT</h1>

                                    <p className="mb-4 leading-relaxed text-gray-700 md:mb-6 xl:text-lg">
                                        <Trans i18nKey="Top.dflatText" />
                                    </p>

                                    <div className="flex flex-col gap-2.5 sm:flex-row sm:justify-center md:justify-start">
                                        <Link
                                            to={DETAIL_DF_LAT_ROUTE}
                                            className="inline-block rounded-lg border border-sky-800 bg-white px-6 py-3 text-center text-sm font-semibold text-gray-700 transition duration-100 hover:bg-sky-100 focus-visible:ring active:bg-sky-200 md:text-base"
                                        >
                                            {t("Top.moreInfo")}
                                        </Link>
                                    </div>
                                </div>

                                <div className="h-auto overflow-hidden rounded-lg bg-gray-100 shadow-lg md:my-5 md:w-5/12 xl:w-4/12">
                                    <img src={dflat_top_img} className="h-full w-full object-cover object-center" />
                                </div>
                            </div>
                        </section>
                    </div>
                </AnimationTrigger>

                {/* DF Viewer */}
                <AnimationTrigger animation="fadeIn" rootMargin="100px" triggerOnce>
                    <div className="my-3">
                        <section className="bg-gradient-to-b from-white via-lime-100 to-white p-5 sm:px-10">
                            <div className="mx-auto flex max-w-screen-xl flex-col justify-between gap-3 sm:gap-8 md:flex-row">
                                <div className="flex flex-col justify-center sm:text-center md:w-7/12 md:text-left xl:w-8/12">
                                    <p className="mb-4 font-semibold text-lime-700 md:text-lg xl:text-xl">
                                        {t("Top.dfviewerHeader")}
                                    </p>
                                    <h1 className="mb-4 text-3xl font-bold text-lime-950 sm:text-4xl md:mb-6">
                                        DF Viewer
                                    </h1>

                                    <p className="mb-4 leading-relaxed text-gray-700 md:mb-6 xl:text-lg">
                                        <Trans i18nKey="Top.dfviewerText" />
                                    </p>

                                    <div className="flex flex-col gap-2.5 sm:flex-row sm:justify-center md:justify-start">
                                        <Link
                                            to={DETAIL_DF_VIEWER_ROUTE}
                                            className="inline-block rounded-lg border border-lime-800 bg-white px-6 py-3 text-center text-sm font-semibold text-gray-700 transition duration-100 hover:bg-lime-100 focus-visible:ring active:bg-lime-200 md:text-base"
                                        >
                                            {t("Top.moreInfo")}
                                        </Link>
                                    </div>
                                </div>

                                <div className="h-auto overflow-hidden rounded-lg bg-gray-100 shadow-lg md:my-5 md:w-5/12 xl:w-4/12">
                                    <img src={dfviewer_top_img} className="h-full w-full object-cover object-center" />
                                </div>
                            </div>
                        </section>
                    </div>
                </AnimationTrigger>

                {/* DF Walker */}
                <AnimationTrigger animation="fadeIn" rootMargin="100px" triggerOnce>
                    <div className="my-3">
                        <section className="bg-gradient-to-b from-white via-sky-100 to-white p-5 sm:px-10">
                            <div className="mx-auto flex max-w-screen-xl flex-col justify-between gap-3 sm:gap-8 md:flex-row">
                                <div className="flex flex-col justify-center sm:text-center md:w-7/12 md:text-left xl:w-8/12">
                                    <p className="mb-4 font-semibold text-sky-800 md:text-lg xl:text-xl">
                                        {t("Top.dfwalkerHeader")}
                                    </p>
                                    <h1 className="mb-4 text-3xl font-bold text-sky-950 sm:text-4xl md:mb-6">
                                        DF Walker
                                    </h1>

                                    <p className="mb-4 leading-relaxed text-gray-700 md:mb-6 xl:text-lg">
                                        <Trans i18nKey="Top.dfwalkerText" />
                                    </p>

                                    <div className="flex flex-col gap-2.5 sm:flex-row sm:justify-center md:justify-start">
                                        <Link
                                            to={DETAIL_DF_WALKER_ROUTE}
                                            className="inline-block rounded-lg border border-sky-800 bg-white px-6 py-3 text-center text-sm font-semibold text-gray-700 transition duration-100 hover:bg-sky-100 focus-visible:ring active:bg-sky-200 md:text-base"
                                        >
                                            {t("Top.moreInfo")}
                                        </Link>
                                    </div>
                                </div>

                                <div className="h-auto overflow-hidden rounded-lg bg-gray-100 shadow-lg md:my-5 md:w-5/12 xl:w-4/12">
                                    <img src={dfwalker_top_img} className="h-full w-full object-cover object-center" />
                                </div>
                            </div>
                        </section>
                    </div>
                </AnimationTrigger>

                {/* DF BIRD */}
                <AnimationTrigger animation="fadeIn" rootMargin="100px" triggerOnce>
                    <div className="my-3">
                        <section className="bg-gradient-to-b from-white via-lime-100 to-white p-5 sm:px-10">
                            <div className="mx-auto flex max-w-screen-xl flex-col justify-between gap-3 sm:gap-8 md:flex-row">
                                <div className="flex flex-col justify-center sm:text-center md:w-7/12 md:text-left xl:w-8/12">
                                    <p className="mb-4 font-semibold text-lime-700 md:text-lg xl:text-xl">
                                        {t("Top.dfbirdHeader")}
                                    </p>
                                    <h1 className="mb-4 text-3xl font-bold text-lime-950 sm:text-4xl md:mb-6">
                                        DF BIRD
                                    </h1>

                                    <p className="mb-4 leading-relaxed text-gray-700 md:mb-6 xl:text-lg">
                                        <Trans i18nKey="Top.dfbirdText" />
                                    </p>

                                    <div className="flex flex-col gap-2.5 sm:flex-row sm:justify-center md:justify-start">
                                        <Link
                                            to={DETAIL_DF_BIRD_ROUTE}
                                            className="inline-block rounded-lg border border-lime-800 bg-white px-6 py-3 text-center text-sm font-semibold text-gray-700 transition duration-100 hover:bg-lime-100 focus-visible:ring active:bg-lime-200 md:text-base"
                                        >
                                            {t("Top.moreInfo")}
                                        </Link>
                                    </div>
                                </div>

                                <div className="h-auto overflow-hidden rounded-lg bg-gray-100 shadow-lg md:my-5 md:w-5/12 xl:w-4/12">
                                    <img src={dfbird_top_img} className="h-full w-full object-cover object-center" />
                                </div>
                            </div>
                        </section>
                    </div>
                </AnimationTrigger>
            </div>
            <Footer />
        </div>
    );
};

export default Top;
