import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import ja from "date-fns/locale/ja";
import DatePicker, { registerLocale } from "react-datepicker";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { LICENSE_ISSUE_COMPLETE_ROUTE, LICENSE_ISSUE_START_ROUTE } from "../consts/routes";
import { CrossCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import {
    AGENCY_ID_DF,
    JPY,
    PRODUCT_TYPE_ID_DF_BIRD,
    PRODUCT_TYPE_ID_DF_LAT_LITE,
    PRODUCT_TYPE_ID_DF_LAT_PRO,
    PRODUCT_TYPE_ID_DF_SCANNER_LITE,
    PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_LITE,
    PRODUCT_TYPE_ID_DF_SCANNER_PRO,
    PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_PRO,
    ProductType,
} from "../consts/constants";
import { parseStringToInt } from "../utils/ValidateUtils";
import { Product } from "../types/Product";
import { getProduct, getProducts } from "../clients/ProductClient";
import { convertUtcToLocalDate, formatDateTime, getYearsLaterDate } from "../utils/DateUtils";
import downarrow from "./../img/downarrow.png";
import { formatPrice, getDfLatNum, getDfScannerNum, getRetailPrice } from "../utils/ProductUtils";
import { useUserContext } from "../contexts/UserContext";
import { User } from "../types/User";
import { checkoutWithoutPayment } from "../clients/PaymentClient";
import { PaymentHistoryWithLicenses } from "../types/PaymentHistoryWithLicenses";
import { PaymentHistory } from "../types/PaymentHistory";
import { OnlyMasterUserAuthorized } from "../components/OnlyMasterUserAuthorized";
import { getAgencies } from "../clients/AgencyClient";
import { Agency } from "../types/Agency";
import { Helmet } from "react-helmet";

type LicenseInfo = {
    license_type_id: number;
    license_type_name_jp: string;
    license_type_name_en: string;
};

interface LocationState {
    selectedUser: User;
    selectedPaymentHistory: PaymentHistory;
    isUpdate: boolean;
}

/**
 * ライセンス発行内容確認画面
 * @returns JSX.Element
 */
const LicenseIssueConfirm = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedUser, selectedPaymentHistory, isUpdate } = location.state as LocationState;
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [unitPrice, setUnitPrice] = useState<number>(0);
    const { getRecentToken } = useUserContext();

    const [agencyList, setAgencyList] = useState<Agency[]>([]);
    const [productList, setProductList] = useState<Product[]>([]);
    const [licenseTypeList, setLicenseTypeList] = useState<LicenseInfo[]>([]);
    const [quantityList, setQuantityList] = useState<number[]>([]);

    const [selectedAgencyId, setSelectedAgencyId] = useState<number | null>(null);
    const [selectedProductTypeId, setSelectedProductTypeId] = useState<number | null>(null);
    const [selectedLicenseTypeId, setSelectedLicenseTypeId] = useState<number | null>(null);
    const [selectedQuantity, setSelectedQuantity] = useState<number | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [selectedCurrency, setSelectedCurrency] = useState<string>(JPY);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

    const [dfscannerNum, setDfScannerNum] = useState<number>(0);
    const [dflatNum, setDflatNum] = useState<number>(0);
    const [isExipred, setIsExpired] = useState<Boolean>(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    registerLocale("ja", ja);

    const productTypeIdList = [
        PRODUCT_TYPE_ID_DF_SCANNER_PRO,
        PRODUCT_TYPE_ID_DF_SCANNER_LITE,
        PRODUCT_TYPE_ID_DF_LAT_PRO,
        PRODUCT_TYPE_ID_DF_LAT_LITE,
        PRODUCT_TYPE_ID_DF_SCANNER_PRO_DF_LAT_PRO,
        PRODUCT_TYPE_ID_DF_SCANNER_LITE_DF_LAT_LITE,
        PRODUCT_TYPE_ID_DF_BIRD,
    ];

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    /**
     * 代理店情報の取得
     *
     * @returns
     */
    async function fetchAgencies() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]代理店情報一覧取得
            const response: Agency[] = await getAgencies(idToken);
            setAgencyList(response);
            // デフォルトに直販を設定
            setSelectedAgencyId(response.map((a) => a.agency_id).find((id) => id === AGENCY_ID_DF) || AGENCY_ID_DF);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    /**
     * 商品情報の取得処理（単体）
     *
     * ライセンス更新の場合に購入商品情報取得のために使用
     * @returns
     */
    async function fetchProduct() {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            // [API]商品情報取得
            const response: Product = await getProduct(idToken, selectedPaymentHistory.product_id);
            setSelectedProduct(response);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    /**
     * 商品情報の取得処理（複数）
     *
     * ライセンス新規発行の場合の選択肢取得のために使用
     * @returns
     */
    async function fetchProducts(productTypeId: number, agencyId: number) {
        setLoading(true);
        try {
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }
            const ltList: LicenseInfo[] = [];
            // [API]商品情報一覧取得
            const response: Product[] = await getProducts(idToken, productTypeId, agencyId);
            setProductList(response);

            // ライセンスタイプリストの生成
            for (const product of response) {
                if (!ltList.map((i) => i.license_type_id).includes(product.license_type_id)) {
                    ltList.push({
                        license_type_id: product.license_type_id,
                        license_type_name_jp: product.license_type_name_jp,
                        license_type_name_en: product.license_type_name_en,
                    });
                }
            }
            const sortedLtList: LicenseInfo[] = ltList.sort((a, b) => a.license_type_id - b.license_type_id);
            setLicenseTypeList(sortedLtList);
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToFetchInfo"));
            return;
        } finally {
            setLoading(false);
        }
    }

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理（DBから情報の取得）
    useEffect(() => {
        if (!effectRun.current) {
            // 代理店情報の取得
            fetchAgencies();
            if (isUpdate) {
                // 前回購入履歴の商品IDで商品マスタを検索
                fetchProduct();
            }
            return () => {
                effectRun.current = true;
            };
        }
    }, [fetchProduct]);

    // 代理店、商品タイプ選択時に商品一覧を取得する処理
    useEffect(() => {
        // ライセンス更新でない、かつ、代理店および商品タイプが指定されている場合は検索
        if (!isUpdate && selectedProductTypeId !== null && selectedAgencyId !== null) {
            fetchProducts(selectedProductTypeId, selectedAgencyId);
        }
    }, [selectedProductTypeId, selectedAgencyId]);

    // ライセンスタイプ、購入数選択時に商品を特定するための処理
    useEffect(() => {
        setSelectedProduct(
            productList.find(
                (product) => product.license_type_id === selectedLicenseTypeId && product.quantity === selectedQuantity,
            ) ?? null,
        );
    }, [selectedLicenseTypeId, selectedQuantity, productList]);

    // 金額の自動計算
    useEffect(() => {
        if (selectedProduct) {
            let retailPrice = getRetailPrice(selectedProduct, selectedCurrency);
            setTotalPrice(retailPrice);
            setUnitPrice(Math.floor(retailPrice / selectedProduct.quantity));
        } else {
            setTotalPrice(0);
        }
    }, [selectedProduct, selectedCurrency, selectedQuantity]);

    // ライセンスキー発行数の自動計算
    useEffect(() => {
        if (selectedProduct) {
            setDfScannerNum(getDfScannerNum(selectedProduct));
            setDflatNum(getDfLatNum(selectedProduct));
        }
    }, [selectedProduct]);

    // ライセンス更新の場合に利用開始日をセットする処理
    useEffect(() => {
        if (isUpdate && selectedProduct) {
            if (new Date() > convertUtcToLocalDate(selectedPaymentHistory.expire_date)) {
                // 有効期限切れの場合 -> 利用開始日は現在日時をセット
                setIsExpired(true);
                setSelectedDate(new Date());
            } else {
                // 有効期限前の場合 -> 利用開始日として有効期限日時をセット（選択不可）
                setIsExpired(false);
                setSelectedDate(convertUtcToLocalDate(selectedPaymentHistory.expire_date));
            }
        }
    }, [selectedProduct]);

    /**
     * 代理店選択時のイベント処理
     * @param event
     */
    const handleSelectAgency: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const id = agencyList.map((a) => a.agency_id).find((id) => id === parseStringToInt(event.target.value));
        if (id !== undefined) {
            setSelectedAgencyId(id);
        }
    };

    /**
     * 商品タイプ選択時のイベント処理
     * @param event
     */
    const handleSelectProductType: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedProductTypeId(parseStringToInt(event.target.value));
    };

    /**
     * ライセンスタイプ選択時のイベント処理
     * @param event
     */
    const handleSelectLicenseType: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedLicenseTypeId(parseStringToInt(event.target.value));
        // 指定のライセンスタイプで選択可能な購入数をフィルタリング
        const qList: number[] = productList
            .filter((product) => product.license_type_id === parseStringToInt(event.target.value))
            .map((product) => product.quantity)
            .sort();
        setQuantityList(qList);
    };

    /**
     * 購入数選択時のイベント処理
     * @param event
     */
    const handleSelectQuantity: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedQuantity(parseStringToInt(event.target.value));
    };

    /** 日付選択時のイベント処理 */
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    /**
     * 戻る押下時のイベント処理
     */
    const handleBackToSelection = () => {
        navigate(LICENSE_ISSUE_START_ROUTE);
    };

    /**
     * ライセンスキーを発行押下時のイベント処理
     */
    const handleForwardToPayment = async () => {
        setErrorMessage("");
        if (isProcessing) return;
        setIsProcessing(true);
        try {
            if (!selectedProduct) {
                setErrorMessage(t("ErrorMessage.productNotSelected"));
                return;
            }
            if (!selectedDate) {
                setErrorMessage(t("ErrorMessage.activationDateNotSelected"));
                return;
            }
            // トークンチェック&取得
            const idToken = await getRecentToken();
            if (!idToken) {
                setErrorMessage(t("ErrorMessage.failToGetIdpToken"));
                return;
            }

            // [API] Chekout API（決済なし）
            // ※更新の場合のみ購入履歴管理番号を渡す
            const response: PaymentHistoryWithLicenses = await checkoutWithoutPayment(
                idToken,
                selectedProduct.product_id,
                selectedUser.email,
                1, // 現状は1固定
                selectedDate?.toUTCString(),
                isUpdate ? selectedPaymentHistory.payment_history_id : undefined,
            );
            // 発行完了画面
            navigate(LICENSE_ISSUE_COMPLETE_ROUTE, { state: { chekoutResult: response } });
        } catch (error) {
            setErrorMessage(t("ErrorMessage.failToCheckoutManual"));
            return;
        } finally {
            setIsProcessing(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("LicenseIssueConfirm.meta.title")} />
            <ScrollToTop />
            {/* マスターユーザーのみ閲覧可 */}
            <OnlyMasterUserAuthorized />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto max-w-3xl flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("LicenseIssueConfirm.title")}</h1>

                {/* エラーメッセージ */}
                {errorMessage && (
                    <div className="flex items-center justify-center p-2">
                        <CrossCircleIcon />
                        <span className="pl-1 text-center font-bold text-red-600">
                            {errorMessage.split("\n").map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </span>
                    </div>
                )}

                {/* 商品検索中 */}
                {loading && (
                    <div className="flex flex-col items-center justify-center pt-4">
                        <div className="h-8 w-8 animate-spin rounded-full border-t-4 border-lime-800"></div>
                        <p className="p-2 font-bold text-lime-800">{t("LicenseIssueConfirm.productsLoading")}</p>
                    </div>
                )}

                <div className="m-0 items-center justify-center rounded-lg border border-gray-300 p-2 shadow sm:m-2">
                    <p className="mx-auto py-2 text-center text-lg font-bold">
                        {t("LicenseIssueConfirm.selectContent")}
                    </p>
                    <div className="grid grid-cols-3 px-5 sm:px-10">
                        {/* 代理店選択 */}
                        {!isUpdate && (
                            <>
                                <div className="col-span-3 my-auto p-3 sm:col-span-1">
                                    {t("CommonLabel.directOrAgent")}
                                </div>
                                <div className="col-span-3 my-auto pb-3 sm:col-span-2 sm:p-3">
                                    <select
                                        id="license-type-dropdown"
                                        className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-xs text-gray-900 sm:text-sm"
                                        onChange={handleSelectAgency}
                                        defaultValue={selectedAgencyId ? selectedAgencyId : undefined}
                                    >
                                        {!selectedAgencyId && <option hidden>{t("CommonMessage.pleaseSelect")}</option>}
                                        {agencyList.map((agency) => (
                                            <option key={agency.agency_id} value={agency.agency_id}>
                                                {agency.agency_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}

                        {/* 商品タイプ */}
                        {!isUpdate && (
                            <>
                                <div className="col-span-3 my-auto p-3 sm:col-span-1">
                                    {t("CommonLabel.productType")}
                                </div>
                                <div className="col-span-3 my-auto pb-3 sm:col-span-2 sm:p-3">
                                    <select
                                        id="product-type-dropdown"
                                        className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-xs text-gray-900 sm:text-sm"
                                        onChange={handleSelectProductType}
                                        defaultValue={selectedProductTypeId ? selectedProductTypeId : undefined}
                                        disabled={isUpdate}
                                    >
                                        {!selectedLicenseTypeId && (
                                            <option hidden>{t("CommonMessage.pleaseSelect")}</option>
                                        )}
                                        {productTypeIdList.map((productTypeId) => (
                                            <option key={productTypeId} value={productTypeId}>
                                                {ProductType[productTypeId]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}

                        {/* ライセンスタイプ */}
                        {!isUpdate && (
                            <>
                                <div className="col-span-3 my-auto flex p-3 sm:col-span-1">
                                    <div>{t("CommonLabel.licenseType")}</div>
                                </div>
                                <div className="col-span-3 my-auto pb-3 sm:col-span-2 sm:p-3">
                                    <select
                                        id="license-type-dropdown"
                                        className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-xs text-gray-900 sm:text-sm"
                                        onChange={handleSelectLicenseType}
                                        disabled={isUpdate || (selectedProductTypeId ? false : true)}
                                        defaultValue={selectedLicenseTypeId ? selectedLicenseTypeId : undefined}
                                    >
                                        {!selectedLicenseTypeId && (
                                            <option hidden>
                                                {selectedProductTypeId ? t("CommonMessage.pleaseSelect") : "-"}
                                            </option>
                                        )}
                                        {licenseTypeList.map((licenseInfo) => (
                                            <option
                                                key={licenseInfo.license_type_id}
                                                value={licenseInfo.license_type_id}
                                            >
                                                {i18n.language === "ja"
                                                    ? licenseInfo.license_type_name_jp
                                                    : licenseInfo.license_type_name_en}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}

                        {/* 購入数 */}
                        {!isUpdate && (
                            <>
                                <div className="col-span-3 my-auto p-3 sm:col-span-1">{t("CommonLabel.quantity")}</div>
                                <div className="col-span-3 my-auto pb-3 sm:col-span-2 sm:p-3">
                                    <select
                                        id="quantity-dropdown"
                                        className="w-1/2 rounded-lg border border-gray-300 bg-gray-50 p-2 text-xs text-gray-900 sm:text-sm"
                                        onChange={handleSelectQuantity}
                                        disabled={isUpdate || (selectedLicenseTypeId != null ? false : true)}
                                        defaultValue={selectedQuantity ? selectedQuantity : undefined}
                                    >
                                        {!selectedQuantity && (
                                            <option hidden>
                                                {selectedLicenseTypeId != null ? t("CommonMessage.pleaseSelect") : "-"}
                                            </option>
                                        )}
                                        {quantityList.map((quantity) => (
                                            <option key={quantity} value={quantity}>
                                                {quantity}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}

                        {/* 利用開始日 */}
                        <div className="col-span-3 my-auto p-3 sm:col-span-1">{t("CommonLabel.activationDate")}</div>
                        <div className="col-span-3 my-auto pb-3 sm:col-span-2 sm:p-3">
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date: Date | null) => handleDateChange(date)}
                                disabled={false} // 編集は必要に応じて可能としておく
                                showTimeSelect
                                minDate={new Date()}
                                maxDate={getYearsLaterDate(1)}
                                className="rounded-lg border border-gray-300 bg-gray-50 p-2 text-xs text-gray-900 sm:text-sm"
                                dateFormat={i18n.language === "ja" ? "yyyy/MM/dd HH:mm" : "dd/MM/yyyy hh:mm a"}
                                locale={i18n.language === "ja" ? "ja" : undefined}
                            />
                        </div>
                        {isUpdate && (
                            // ライセンス更新時の利用開始日に関する注意書き
                            <>
                                <div className="col-span-3 pl-3 text-sm font-bold text-lime-700">
                                    *{t("LicenseIssueConfirm.activationDateNote1")}
                                </div>
                                <div className="col-span-3 pl-3 text-sm font-bold text-lime-700">
                                    *{t("LicenseIssueConfirm.activationDateNote2")}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {/* 下矢印 */}
                <div className="flex items-center justify-center">
                    <img className="h-1/6 w-1/6 p-2" src={downarrow} alt="downarrow" />
                </div>

                {/* 購入内容表示パネル */}
                <div className="m-0 rounded-lg border border-gray-200 bg-yellow-50 p-2 shadow sm:m-2">
                    <p className="mx-auto py-2 text-center text-lg font-bold">
                        {isUpdate ? t("LicenseIssueConfirm.updateFollowing") : t("LicenseIssueConfirm.issueFollowing")}
                    </p>
                    {/* 商品名 */}
                    <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                        <span className="">{t("CommonLabel.productName")}:&nbsp;&nbsp;&nbsp;</span>
                        <div id="product-name" className="">
                            {selectedProduct
                                ? i18n.language === "ja"
                                    ? selectedProduct.product_name_jp
                                    : selectedProduct.product_name_en
                                : t("CommonLabel.unselected")}
                        </div>
                    </div>

                    {/* ライセンス発行数 */}
                    {selectedProduct && dfscannerNum > 0 ? (
                        <div id="dfscanner-num" className="flex items-center px-10 font-bold text-gray-600">
                            &nbsp;&nbsp;*
                            <Trans
                                i18nKey={
                                    isUpdate
                                        ? "LicenseIssueConfirm.updatedLicensesNum"
                                        : "LicenseIssueConfirm.issuedLicensesNum"
                                }
                                values={{ productName: "DF Scanner", licenseNum: dfscannerNum }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {selectedProduct && dflatNum > 0 ? (
                        <div id="dflat-num" className="flex items-center px-10 font-bold text-gray-600">
                            &nbsp;&nbsp;*
                            <Trans
                                i18nKey={"LicenseIssueConfirm.issuedLicensesNum"}
                                values={{ productName: "DF LAT", licenseNum: dflatNum }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    {/* 利用開始日 */}
                    <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                        <span className="">{t("CommonLabel.activationDate")}:&nbsp;&nbsp;&nbsp;</span>
                        {/* 表示時のタイムゾーンはランタイム環境に依存 */}
                        <div className="">
                            {selectedDate ? formatDateTime(selectedDate) : t("CommonLabel.unselected")}
                        </div>
                    </div>

                    {/* 単価 */}
                    <div className="px-10 py-2 font-bold sm:flex sm:items-center">
                        <span className="">{t("CommonLabel.unitPrice")}:&nbsp;&nbsp;&nbsp;</span>
                        <div id="unit-price" className="">
                            {formatPrice(unitPrice, selectedCurrency)}
                        </div>
                    </div>

                    <hr className="mx-8 my-2 border border-gray-300" />

                    {/* 合計金額 */}
                    <div className="flex items-center pl-10 pt-2 text-lg font-bold text-orange-600">
                        <span className="">{t("CommonLabel.totalAmount")}:&nbsp;</span>
                        <div id="total-price" className="">
                            {formatPrice(totalPrice, selectedCurrency)}
                        </div>
                    </div>
                    <div className="pb-5 pl-8 pt-2  text-base font-bold text-red-700">
                        &nbsp;&nbsp;*{t("LicenseIssueConfirm.skipPayment")}
                    </div>
                </div>

                {/* 戻る/進むボタン */}
                <div className="flex justify-evenly pb-5 pt-5 text-sm font-medium">
                    <button onClick={handleBackToSelection} className="btn-secondary w-1/3">
                        {t("CommonLabel.back")}
                    </button>
                    <button
                        id="payment-btn"
                        onClick={handleForwardToPayment}
                        className={`${isProcessing ? "btn-primary-disabled" : "btn-primary"} w-1/3`}
                        disabled={isProcessing}
                    >
                        {isProcessing ? (
                            <div className="flex items-center justify-center">
                                <div className="mr-1 h-4 w-4 animate-spin rounded-full border-2 border-lime-100 border-t-transparent"></div>
                                <label className="">Processing...</label>
                            </div>
                        ) : isUpdate ? (
                            t("LicenseIssueConfirm.updateLicenseButton")
                        ) : (
                            t("LicenseIssueConfirm.issueLicenseButton")
                        )}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LicenseIssueConfirm;
