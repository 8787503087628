import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import { DOWNLOAD_ROUTE, MYPAGE_ROUTE, USER_LICENSE_REFERENCE_ROUTE, USER_LICENSE_ROUTE } from "../consts/routes";
import { CheckmarkCircleIcon } from "../components/Icons";
import { ScrollToTop } from "../components/ScrollToTop";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { useUserContext } from "../contexts/UserContext";
import { USER_TYPE_LICENSED_USER } from "../consts/constants";
import { Helmet } from "react-helmet";

/**
 * 決済完了時リダイレクト画面
 *
 * ・未認証時にも開けるページとして用意
 * ・Stirpeでの決済後のリダイレクト先ページとして利用
 * @returns JSX.Element
 */
const PaymentComplete = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userType, getRecentToken } = useUserContext();

    // ページ表示時にuseEffectが2回実行されるのを防ぐために使用。
    // ※React.StrictModeの仕様
    const effectRun = useRef(false);

    // ページ初期化処理
    useEffect(() => {
        if (!effectRun.current) {
            return () => {
                effectRun.current = true;
            };
        }
    }, []);

    /**
     * ユーザー・ライセンス画面へ押下時のイベント処理
     */
    const handleMoveToUserLicense = () => {
        if (userType === USER_TYPE_LICENSED_USER) {
            // 一般ユーザーの場合は参照専用画面へ
            navigate(USER_LICENSE_REFERENCE_ROUTE);
        } else {
            // それ以外の場合は通常の管理画面へ
            navigate(USER_LICENSE_ROUTE);
        }
    };

    /**
     * ホーム画面へ戻る押下時のイベント処理
     */
    const handleBackToMyPage = () => {
        navigate(MYPAGE_ROUTE);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("PaymentComplete.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="mx-auto flex-grow items-center justify-center px-6 py-8 pt-20">
                <h1 className="h1-common py-3">{t("PaymentComplete.productCompleteTitle")}</h1>

                {/* 購入完了メッセージ */}
                <div className="flex items-center justify-center pb-4 pt-4">
                    <CheckmarkCircleIcon />
                    <span className="pl-2 text-center text-xl font-bold text-green-500">
                        {t("PaymentComplete.productCompleteMessage")}
                    </span>
                </div>
                <ul className="ml-10 list-disc pb-6 font-bold text-gray-600">
                    <li>
                        <Trans i18nKey={"PaymentComplete.productCompleteText1"} />
                    </li>
                    <li>
                        <Trans i18nKey={"PaymentComplete.productCompleteText2"} />
                    </li>
                </ul>

                {/* ご利用の流れ */}
                <div className="flex items-center justify-center">
                    <div className="m-0 max-w-lg items-center justify-center rounded-lg border border-gray-300 p-2 shadow sm:m-2">
                        <div className="mb-2 text-center text-lg font-bold">
                            {t("PaymentComplete.flowOfUsageTitle")}
                        </div>
                        <ul className="ml-5 list-decimal text-sm text-gray-800">
                            <li>{t("PaymentComplete.flowOfUsageText1")}</li>
                            <ul className="text-gray-600">
                                <li className="">
                                    *
                                    <Trans
                                        i18nKey={"PaymentComplete.installerLink"}
                                        components={{
                                            l: <HashLink className="link-text" to={DOWNLOAD_ROUTE} />,
                                        }}
                                    />
                                </li>
                            </ul>
                            <li className="pt-1">{t("PaymentComplete.flowOfUsageText2")}</li>
                            <li className="pt-1">{t("PaymentComplete.flowOfUsageText3")}</li>
                            <li className="pt-1">{t("PaymentComplete.flowOfUsageText4")}</li>
                            <li className="pt-1">{t("PaymentComplete.flowOfUsageText5")}</li>
                        </ul>
                        <div className="font ml-4 mt-2 text-sm">
                            <Trans i18nKey={"PaymentComplete.flowOfUsageTextOther"} />
                        </div>
                    </div>
                </div>

                {/* ユーザー・ライセンス管理画面へ */}
                <div className="mt-10 flex items-center justify-center">
                    <button onClick={handleMoveToUserLicense} className="text-lx btn-green-white w-max-md h-full w-2/3">
                        {t("PaymentComplete.goToUserLicense")}
                    </button>
                </div>

                {/* マイページ画面へ戻る */}
                <div className="flex items-center justify-center">
                    <button onClick={handleBackToMyPage} className="text-lx btn-secondary my-5 w-2/3">
                        {t("PaymentComplete.backToMyPage")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PaymentComplete;
