import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import DFNavbar from "../../components/Navbar";
import dfbird_icon from "./../../img/dfbird/icon.png";
import dfbird_screen from "./../../img/dfbird/screen.png";
import dfbird_automation from "./../../img/dfbird/automation.png";
import dfbird_calc_area from "./../../img/dfbird/calc-area.png";
import dfbird_calc_distance from "./../../img/dfbird/calc-distance.png";

import { ScrollToTop } from "../../components/ScrollToTop";
import { DOWNLOAD_ROUTE } from "../../consts/routes";
import { Helmet } from "react-helmet";

/**
 * DF BIRD詳細画面
 * @returns JSX.Element
 */
const DetailDfbird = () => {
    const { t } = useTranslation();

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("DetailDfbird.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <img className="h-20 w-20 p-3" src={dfbird_icon} alt="dflat" />
                        <h1 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl ">DF BIRD</h1>
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfbird_screen}
                            alt="DF Viewer Screen Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 概要 */}
                    <p className="mb-3 text-gray-700 sm:text-lg md:mb-4">
                        <Trans i18nKey="DetailDfbird.about1" />
                    </p>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfbird.about2" />
                    </p>

                    {/* 全自動解析 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfbird.automationTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfbird.automationText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfbird_automation}
                            alt="DF BIRD Automation Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* 結果の確認 */}
                    <h2 className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfbird.checkResultTitle")}
                    </h2>
                    <p className="mb-6 text-gray-700 sm:text-lg md:mb-8">
                        <Trans i18nKey="DetailDfbird.checkResultText" />
                    </p>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfbird_calc_area}
                            alt="DF BIRD Check Area Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className="relative my-8 overflow-hidden rounded-lg md:mb-8">
                        <img
                            src={dfbird_calc_distance}
                            alt="DF BIRD Check Distance Image"
                            className="h-full w-full object-cover object-center"
                        />
                    </div>

                    {/* ダウンロード */}
                    <h2 id="download" className="mb-2 mt-16 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("DetailDfviewer.downloadTitle")}
                    </h2>
                    <p className="mb-2 text-gray-700 sm:text-lg md:mb-2">
                        <Link to={DOWNLOAD_ROUTE}>
                            <Trans
                                i18nKey="DetailDfviewer.downloadText"
                                components={{
                                    l: <button className="link-text" />,
                                }}
                            />
                        </Link>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default DetailDfbird;
