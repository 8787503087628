import { MouseEventHandler, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import DFNavbar from "../components/Navbar";
import dfscanner_icon from "./../img/dfscanner/icon.png";
import dfscanner_icon_lite from "./../img/dfscanner/icon_lite.png";
import dfviewer_icon from "./../img/dfviewer/icon.jpg";
import dflat_icon from "./../img/dflat/icon.png";
import dflat_icon_lite from "./../img/dflat/icon_lite.png";
import dfwalker_icon from "./../img/dfwalker/icon.png";
import dfbird_icon from "./../img/dfbird/icon.png";
import { ScrollToTop } from "../components/ScrollToTop";
import { PRODUCT_SELECT_ROUTE, RELEASE_NOTES_ROUTE } from "../consts/routes";
import {
    DF_BIRD_FILENAME,
    DF_BIRD_MANUAL_EN_FILENAME,
    DF_BIRD_MANUAL_EN_URL,
    DF_BIRD_MANUAL_JA_FILENAME,
    DF_BIRD_MANUAL_JA_URL,
    DF_BIRD_URL,
    DF_LAT_LITE_FILENAME,
    DF_LAT_LITE_MANUAL_EN_FILENAME,
    DF_LAT_LITE_MANUAL_EN_URL,
    DF_LAT_LITE_MANUAL_JA_FILENAME,
    DF_LAT_LITE_MANUAL_JA_URL,
    DF_LAT_LITE_URL,
    DF_LAT_PRO_FILENAME,
    DF_LAT_PRO_MANUAL_EN_FILENAME,
    DF_LAT_PRO_MANUAL_EN_URL,
    DF_LAT_PRO_MANUAL_JA_FILENAME,
    DF_LAT_PRO_MANUAL_JA_URL,
    DF_LAT_PRO_URL,
    DF_SCANNER_FILENAME,
    DF_SCANNER_LITE_FILENAME,
    DF_SCANNER_LITE_MANUAL_EN_FILENAME,
    DF_SCANNER_LITE_MANUAL_EN_URL,
    DF_SCANNER_LITE_MANUAL_JA_FILENAME,
    DF_SCANNER_LITE_MANUAL_JA_URL,
    DF_SCANNER_LITE_URL,
    DF_SCANNER_PRO_FILENAME,
    DF_SCANNER_PRO_MANUAL_EN_FILENAME,
    DF_SCANNER_PRO_MANUAL_EN_URL,
    DF_SCANNER_PRO_MANUAL_JA_FILENAME,
    DF_SCANNER_PRO_MANUAL_JA_URL,
    DF_SCANNER_PRO_URL,
    DF_SCANNER_TESTDATA_FILENAME,
    DF_SCANNER_TESTDATA_URL,
    DF_SCANNER_URL,
    DF_VIEWER_FILENAME,
    DF_VIEWER_MANUAL_JA_FILENAME,
    DF_VIEWER_MANUAL_JA_URL,
    DF_VIEWER_URL,
} from "../consts/Installers";
import i18n from "../i18n/configs";
import DFWalkerDownloadModal from "./modals/DFWalkerDownloadModal";
import { Helmet } from "react-helmet";

const DF_SCANNER_VAL = 1;
const DF_VIEWER_VAL = 2;
const DF_LAT_VAL = 3;
const DF_BIRD_VAL = 4;

/**
 * ダウンロード画面
 * @returns JSX.Element
 */
const Download = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showDFWalkerDownloadModal, setShowDFWalkerDownloadModal] = useState(false);

    /**
     * 商品選択時のイベント処理
     * @param event
     */
    const handleSelectProduct: MouseEventHandler<HTMLButtonElement> = () => {
        // 商品選択画面へ
        // ※購入内容確認画面の前に所在地確認を入れたいため商品選択画面に移動させる
        navigate(PRODUCT_SELECT_ROUTE);
    };

    /**
     * DF Walkerダウンロード用モーダルを開く
     */
    const handleOpenDFWalkerDownloadModal = () => {
        setShowDFWalkerDownloadModal(true);
    };

    /**
     * DF Walkerダウンロード用モーダルを閉じる
     */
    const handleCloseDFWalkerDownloadModal = () => {
        setShowDFWalkerDownloadModal(false);
    };

    return (
        <div className="flex min-h-screen flex-col">
            <Helmet title={t("Download.meta.title")} />
            <ScrollToTop />
            <DFNavbar bottomPadding={true} />
            <div className="bg-white pb-10 pt-20">
                <div className="mx-auto max-w-screen-md px-4 md:px-8">
                    <div className="flex items-center justify-center">
                        <h1 className="mb-8 mt-2 text-center text-2xl font-bold text-gray-800 sm:text-3xl">
                            {t("Download.title")}
                        </h1>
                    </div>

                    {/* DF Scanner */}
                    <div className="flex items-center">
                        <img className="h-16 w-16 p-1" src={dfscanner_icon} alt="dfscanner" />
                        <h2 id="dfscanner" className="pl-2 text-3xl font-semibold text-gray-800 sm:text-4xl">
                            DF Scanner
                        </h2>
                        <label className="pl-2 text-3xl font-semibold text-gray-800 sm:text-4xl">
                            {t("CommonLabel.pro")} / {t("CommonLabel.lite")}
                        </label>
                    </div>
                    <p className="mb-2 py-2 pl-2 text-lg">{t("Download.dfscanner.about")}</p>
                    <table className="min-w-full border border-gray-300">
                        <tbody>
                            {/* Pro版 */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-lime-800">
                                    {t("CommonLabel.pro")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">DF Scanner Pro</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_SCANNER_PRO_URL}
                                    >
                                        {DF_SCANNER_PRO_FILENAME}
                                    </a>
                                </td>
                            </tr>
                            {/* Pro版 ユーザーマニュアル */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-lime-800">
                                    {t("CommonLabel.pro")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.userManual")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_SCANNER_PRO_MANUAL_JA_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_SCANNER_PRO_MANUAL_JA_FILENAME}
                                    </a>
                                    <br />
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_SCANNER_PRO_MANUAL_EN_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_SCANNER_PRO_MANUAL_EN_FILENAME}
                                    </a>
                                </td>
                            </tr>

                            {/* Lite版 */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-lime-500">
                                    {t("CommonLabel.lite")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">DF Scanner Lite</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_SCANNER_LITE_URL}
                                    >
                                        {DF_SCANNER_LITE_FILENAME}
                                    </a>
                                </td>
                            </tr>
                            {/* Lite版 ユーザーマニュアル */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-lime-500">
                                    {t("CommonLabel.lite")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.userManual")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_SCANNER_LITE_MANUAL_JA_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_SCANNER_LITE_MANUAL_JA_FILENAME}
                                    </a>
                                    <br />
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_SCANNER_LITE_MANUAL_EN_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_SCANNER_LITE_MANUAL_EN_FILENAME}
                                    </a>
                                </td>
                            </tr>

                            {/* 旧版 */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-gray-400">
                                    {t("CommonLabel.old")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">DF Scanner</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_SCANNER_URL}
                                    >
                                        {DF_SCANNER_FILENAME}
                                    </a>
                                </td>
                            </tr>
                            {/* 旧版 ユーザーマニュアル */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-gray-400">
                                    {t("CommonLabel.old")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.userManual")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <p className="text-sm text-gray-600">*{t("Download.dfscanner.referProManual")}</p>
                                </td>
                            </tr>

                            {/* サンプルデータ */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-sm font-bold text-lime-700"></td>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.sampleData")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_SCANNER_TESTDATA_URL}
                                    >
                                        {DF_SCANNER_TESTDATA_FILENAME}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* 旧版について */}
                    <p className="mt-2 text-gray-700">
                        *
                        <Trans
                            i18nKey="Download.dfscanner.oldVerText"
                            components={{
                                l: <button className="link-text" onClick={handleSelectProduct} />,
                            }}
                        />
                    </p>
                    {/* GPU版/CPU版について */}
                    <p className="mb-2 text-gray-700">
                        *
                        <Trans
                            i18nKey="Download.dfscanner.gpuCpuText"
                            components={{
                                l: <button className="link-text" onClick={handleSelectProduct} />,
                            }}
                        />
                    </p>

                    <p className="my-2 text-base font-bold text-orange-700">{t("Download.dfscanner.aboutDfbird")}</p>

                    {/* DF Scanner ライセンス購入 */}
                    <p className="mb-2 mt-6 text-gray-700 sm:text-xl md:mb-2">
                        ◇&nbsp;
                        <Trans
                            i18nKey="Download.dfscanner.purchaseLicensesText1"
                            components={{
                                l: <button className="link-text" onClick={handleSelectProduct} />,
                            }}
                        />
                    </p>
                    {/* リリースノート */}
                    <p className="mb-2 mt-6 text-gray-700 sm:text-xl md:mb-2">
                        ◇&nbsp;
                        <Link to={RELEASE_NOTES_ROUTE} state={{ productValue: DF_SCANNER_VAL }}>
                            <Trans
                                i18nKey="Download.releaseHistory"
                                components={{
                                    l: <button className="link-text" />,
                                }}
                            />
                        </Link>
                    </p>

                    <hr className="mb-4 mt-16 border-2"></hr>

                    {/* DF Viewer */}
                    <div className="flex items-center">
                        <img className="h-16 w-16 p-1" src={dfviewer_icon} alt="dfviewer" />
                        <h2 id="dfviewer" className="pl-2 text-3xl font-semibold text-gray-800 sm:text-4xl">
                            DF Viewer
                        </h2>
                    </div>
                    <p className="mb-2 py-2 pl-2 text-lg">{t("Download.dfviewer.about")}</p>
                    <table className="min-w-full border border-gray-300">
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">DF Viewer</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_VIEWER_URL}
                                    >
                                        {DF_VIEWER_FILENAME}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfviewer.userManual")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_VIEWER_MANUAL_JA_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_VIEWER_MANUAL_JA_FILENAME}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {/* リリースノート */}
                    <p className="mb-2 mt-6 text-gray-700 sm:text-xl md:mb-2">
                        ◇&nbsp;
                        <Link to={RELEASE_NOTES_ROUTE} state={{ productValue: DF_VIEWER_VAL }}>
                            <Trans
                                i18nKey="Download.releaseHistory"
                                components={{
                                    l: <button className="link-text" />,
                                }}
                            />
                        </Link>
                    </p>

                    <hr className="mb-4 mt-16 border-2"></hr>

                    {/* DF LAT */}
                    <div className="flex items-center">
                        <img className="h-16 w-16 p-1" src={dflat_icon} alt="dflat" />
                        <h2 id="dflat" className="pl-2 text-3xl font-semibold text-gray-800 sm:text-4xl">
                            DF LAT
                        </h2>
                        <label className="pl-2 text-3xl font-semibold text-gray-800 sm:text-4xl">
                            {t("CommonLabel.pro")} / {t("CommonLabel.lite")}
                        </label>
                    </div>
                    <p className="mb-2 py-2 pl-2 text-lg">{t("Download.dflat.about")}</p>
                    <table className="min-w-full border border-gray-300">
                        <tbody>
                            {/*  DF LAT Pro版 */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-lime-800">
                                    {t("CommonLabel.pro")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">DF LAT Pro</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_LAT_PRO_URL}
                                    >
                                        {DF_LAT_PRO_FILENAME}
                                    </a>
                                </td>
                            </tr>
                            {/*  DF LAT Pro版 ユーザーマニュアル */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-lime-800">
                                    {t("CommonLabel.pro")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">{t("Download.dflat.userManual")}</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_LAT_PRO_MANUAL_JA_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_LAT_PRO_MANUAL_JA_FILENAME}
                                    </a>
                                    <br />
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_LAT_PRO_MANUAL_EN_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_LAT_PRO_MANUAL_EN_FILENAME}
                                    </a>
                                </td>
                            </tr>

                            {/* Lite版 */}
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-lime-500">
                                    {t("CommonLabel.lite")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">DF LAT Lite</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_LAT_LITE_URL}
                                    >
                                        {DF_LAT_LITE_FILENAME}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-1 py-2 text-center text-sm font-bold text-lime-500">
                                    {t("CommonLabel.lite")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">{t("Download.dflat.userManual")}</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_LAT_LITE_MANUAL_JA_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_LAT_LITE_MANUAL_JA_FILENAME}
                                    </a>
                                    <br />
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_LAT_LITE_MANUAL_EN_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_LAT_LITE_MANUAL_EN_FILENAME}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* 旧版について */}
                    <p className="mb-2 mt-2 text-gray-700 md:mb-2">
                        *<Trans i18nKey="Download.dflat.oldVerText" />
                    </p>

                    {/* DF LAT ライセンス購入 */}
                    <p className="mb-2 mt-6 text-gray-700 sm:text-xl md:mb-2">
                        ◇&nbsp;
                        <Trans
                            i18nKey="Download.dflat.purchaseLicensesText1"
                            components={{
                                l: <button className="link-text" onClick={handleSelectProduct} />,
                            }}
                        />
                    </p>
                    {/* リリースノート */}
                    <p className="mb-2 mt-6 text-gray-700 sm:text-xl md:mb-2">
                        ◇&nbsp;
                        <Link to={RELEASE_NOTES_ROUTE} state={{ productValue: DF_LAT_VAL }}>
                            <Trans
                                i18nKey="Download.releaseHistory"
                                components={{
                                    l: <button className="link-text" />,
                                }}
                            />
                        </Link>
                    </p>

                    <hr className="mb-4 mt-16 border-2"></hr>

                    {/* DF Walker */}
                    <div className="flex items-center">
                        <img className="h-16 w-16 p-1" src={dfwalker_icon} alt="dfwalker" />
                        <h2 id="dfwalker" className="pl-2 text-3xl font-semibold text-gray-800 sm:text-4xl">
                            DF Walker
                        </h2>
                    </div>
                    <p className="mb-2 py-2 pl-2 text-lg">{t("Download.dfwalker.about")}</p>
                    <table className="min-w-full border border-gray-300">
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">DF Walker [Android]</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <button
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        onClick={handleOpenDFWalkerDownloadModal}
                                    >
                                        {t("Download.dfwalker.displayQRCode")}
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.userManual")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={"https://deepforestlicense.com/DFWalker/docs/usermanual.pdf"}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        DF Walker ユーザーマニュアル.pdf
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <hr className="mb-4 mt-16 border-2"></hr>

                    {/* DF BIRD */}
                    <div className="flex items-center">
                        <img className="h-16 w-16 p-1" src={dfbird_icon} alt="dfbird" />
                        <h2 id="dfbird" className="pl-2 text-3xl font-semibold text-gray-800 sm:text-4xl">
                            DF BIRD
                        </h2>
                    </div>
                    <p className="mb-2 py-2 pl-2 text-lg">{t("Download.dfbird.about")}</p>
                    <table className="min-w-full border border-gray-300">
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">DF BIRD</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_BIRD_URL}
                                    >
                                        {DF_BIRD_FILENAME}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfviewer.userManual")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_BIRD_MANUAL_JA_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_BIRD_MANUAL_JA_FILENAME}
                                    </a>
                                    <br />
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={DF_BIRD_MANUAL_EN_URL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {DF_BIRD_MANUAL_EN_FILENAME}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* リリースノート */}
                    <p className="mb-2 mt-6 text-gray-700 sm:text-xl md:mb-2">
                        ◇&nbsp;
                        <Link to={RELEASE_NOTES_ROUTE} state={{ productValue: DF_BIRD_VAL }}>
                            <Trans
                                i18nKey="Download.releaseHistory"
                                components={{
                                    l: <button className="link-text" />,
                                }}
                            />
                        </Link>
                    </p>

                    <hr className="mb-4 mt-16 border-2"></hr>

                    {/* 必須・推奨PCスペック */}
                    <h2 className="mb-2 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">
                        {t("Download.systemRequirementsTitle")}
                    </h2>
                    <table className="min-w-full border border-gray-300">
                        <thead>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2"></th>
                                <th className="border border-gray-300 px-4 py-2">{t("Download.required")}</th>
                                <th className="border border-gray-300 px-4 py-2">{t("Download.recommended")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">OS</td>
                                <td className="border border-gray-300 px-4 py-2">windows 10, 11</td>
                                <td className="border border-gray-300 px-4 py-2">windows 10, 11</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">RAM memory</td>
                                <td className="border border-gray-300 px-4 py-2">4GB</td>
                                <td className="border border-gray-300 px-4 py-2">16GB</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">{t("Download.hardDiskFreeSpace")}</td>
                                <td className="border border-gray-300 px-4 py-2">7GB</td>
                                <td className="border border-gray-300 px-4 py-2">10GB</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">GPU</td>
                                <td className="border border-gray-300 px-4 py-2">{t("Download.notRequired")}</td>
                                <td className="border border-gray-300 px-4 py-2">GeForce RTX series*1</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="my-3 ml-3 text-sm text-gray-700">*1 {t("Download.gpuAttention")}</p>

                    <hr className="mb-4 mt-16 border-2"></hr>

                    {/* 利用可能機能一覧 */}
                    <h2 id="availableTools" className="text-xl font-semibold text-gray-800 sm:text-2xl">
                        {t("Download.availableToolsTitle")}
                    </h2>
                    {/* DF Scanner */}
                    <div className="mt-4 flex items-center py-2 pl-2">
                        <img className="h-8 w-8" src={dfscanner_icon} alt="dfscanner" />
                        <div>
                            <h3 className="pl-2 text-lg font-semibold text-gray-800">DF Scanner</h3>
                            <label className="pl-2 font-semibold text-gray-800">{t("CommonLabel.pro")}</label>
                        </div>
                        <span className="px-2 text-xl font-semibold text-gray-800">/</span>
                        <img className="h-8 w-8" src={dfscanner_icon_lite} alt="dfscanner" />
                        <div>
                            <h3 className="pl-2 text-lg font-semibold text-gray-800">DF Scanner</h3>
                            <label className="pl-2 font-semibold text-gray-800">{t("CommonLabel.lite")}</label>
                        </div>
                    </div>
                    <table className="min-w-full border border-gray-300">
                        <thead>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2"></th>
                                <th className="border border-gray-300 px-4 py-2">
                                    <Trans i18nKey="Download.dfscanner.dfScannerProCol" />
                                </th>
                                <th className="border border-gray-300 px-4 py-2">
                                    <Trans i18nKey="Download.dfscanner.dfScannerLiteCol" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.dataVisualization")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr className={i18n.language === "en" ? "bg-gray-200" : ""}>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.automaticAnalysis")}
                                    {i18n.language === "en" && (
                                        <label className="text-sm font-bold text-red-600">&nbsp;*3</label>
                                    )}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.coniferTreeSegmentation")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">
                                    △<label className="text-sm text-lime-900">*1</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.broadleavedTreeSegmentation")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">×</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.sugiHinokiClassification")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">
                                    △<label className="text-sm text-lime-900">*1</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.japaneseClassification")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">×</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.trainingAndPrediction")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.learningModelCreation")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">×</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.estimationOfTreeHeightAndDbh")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr className={i18n.language === "en" ? "bg-gray-200" : ""}>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.stemVolumeEstimation")}
                                    {i18n.language === "en" && (
                                        <label className="text-sm font-bold text-red-600">&nbsp;*3</label>
                                    )}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr className={i18n.language === "en" ? "bg-gray-200" : ""}>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.carbonStockEstimation")}
                                    {i18n.language === "en" && (
                                        <label className="text-sm font-bold text-red-600">&nbsp;*3</label>
                                    )}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.staticsCalculation")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.saveProject")}
                                    &nbsp;<label className="text-sm text-lime-900">*2</label>
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfscanner.onlineBackup")}
                                    &nbsp;<label className="text-sm text-lime-900">*2</label>
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="text ml-3 text-gray-700">*1 {t("Download.dfscanner.attention1")}</p>
                    <p className="text ml-3 text-gray-700">*2 {t("Download.dfscanner.attention2")}</p>
                    {i18n.language === "en" && (
                        <p className="text ml-3 text-red-600">*3 {t("Download.dfscanner.attention3")}</p>
                    )}

                    {/* DF LAT */}
                    <div className="mt-6 flex items-center py-2 pl-2">
                        <img className="h-8 w-8" src={dflat_icon} alt="dflat" />
                        <div>
                            <h3 className="pl-2 text-lg font-semibold text-gray-800">DF LAT</h3>
                            <label className="pl-2 font-semibold text-gray-800">{t("CommonLabel.pro")}</label>
                        </div>
                        <span className="mx-2 px-2 text-xl font-semibold text-gray-800">/</span>
                        <img className="h-8 w-8" src={dflat_icon_lite} alt="dflat" />
                        <div>
                            <h3 className="pl-2 text-lg font-semibold text-gray-800">DF LAT</h3>
                            <label className="pl-2 font-semibold text-gray-800">{t("CommonLabel.lite")}</label>
                        </div>
                    </div>
                    <table className="min-w-full border border-gray-300">
                        <thead>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2"></th>
                                <th className="border border-gray-300 px-4 py-2">
                                    <Trans i18nKey="Download.dflat.dfLatProCol" />
                                    &nbsp;<label className="text-sm text-lime-900">*1</label>
                                </th>
                                <th className="border border-gray-300 px-4 py-2">
                                    <Trans i18nKey="Download.dflat.dfLatLiteCol" />
                                    &nbsp;<label className="text-sm text-lime-900">*1</label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dflat.pointCloudRendering")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dflat.visualizationOfDsmAndDtm")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dflat.automaticGroundDetection")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">
                                    △<label className="text-sm text-lime-900">*2</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dflat.manualFiltering")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">×</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dflat.creationOfDsmDtmChm")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">
                                    △<label className="text-sm text-lime-900">*2</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dflat.fullyAutomaticProcessing")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dflat.pointCloudCoordinateTransformation")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">×</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dflat.dataSplitingAndMerging")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">×</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="ml-3 mt-3 text-gray-700">*1 {t("Download.dflat.attention1")}</p>
                    <p className="ml-3 text-gray-700">*2 {t("Download.dflat.attention2")}</p>

                    {/* DF Walker */}
                    <div className="mt-6 flex items-center py-2 pl-2">
                        <img className="h-8 w-8" src={dfwalker_icon} alt="dflat" />
                        <div>
                            <h3 className="pl-2 text-xl font-semibold text-gray-800">DF Walker</h3>
                        </div>
                    </div>
                    <table className="min-w-full border border-gray-300">
                        <thead>
                            <tr>
                                <th className="border border-gray-300 px-4 py-2"></th>
                                <th className="border border-gray-300 px-4 py-2">
                                    <Trans i18nKey="Download.dfwalker.dfWalkerForAndroidCol" />
                                    &nbsp;<label className="text-sm text-lime-900"></label>
                                </th>
                                <th className="border border-gray-300 px-4 py-2">
                                    <Trans i18nKey="Download.dfwalker.dfWalkerForIOSCol" />
                                    &nbsp;<label className="text-sm text-lime-900">*1</label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.basicMapFunction")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.currentLocation")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.orthoDisplay")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.polygonDisplay")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.polygonNewFile")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.polygonLabel")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.createDeletePolygon")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.checkEfitPolygonAttribute")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.multilayer")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                            <tr>
                                <td className="border border-gray-300 px-4 py-2">
                                    {t("Download.dfwalker.englishSupport")}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-center">○</td>
                                <td className="border border-gray-300 px-4 py-2 text-center">-</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="ml-3 mt-3 text-gray-700">*1 {t("Download.dfwalker.attention1")}</p>
                </div>
            </div>
            {/* DF Walkerダウンロード用モーダル */}
            <DFWalkerDownloadModal
                showModal={showDFWalkerDownloadModal}
                handleClose={handleCloseDFWalkerDownloadModal}
            />
            <Footer />
        </div>
    );
};

export default Download;
